import React from "react";
import {
  Check,
  Close,
  HorizontalSplit,
  MoreVert,
  SettingsApplications,
  VerticalSplit,
} from "@mui/icons-material";
import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { FileTreeOutline } from "mdi-material-ui";
import { useIsConstrainedHeight, useIsConstrainedWidth } from "~/layout";
import { invariant } from "~/lib/invariant";
import { getEventHandlerProps } from "~/utils";
import { staticConditionalMenuChildren } from "~/utils/static-conditional-menu-children";
import { usePlayerActions } from "../actions";
import { usePlayerTopics } from "../hooks";
import { useControlledPanelContext } from "../layout";
import type { InitializedPanel } from "../panels";
import {
  checkIsPanelInitialized,
  getSupportedVisualizations,
  SplitOrientation,
  usePanelContext,
  VisualizationType,
} from "../panels";

export function PanelHeader() {
  const playerTopicsQuery = usePlayerTopics();

  const panel = usePanelContext();

  const isConstrainedWidth = useIsConstrainedWidth();
  const isConstrainedHeight = useIsConstrainedHeight();
  const iconFontSize = isConstrainedHeight ? "small" : "medium";

  const controlledPanelContext = useControlledPanelContext({ strict: false });
  const isVisible = controlledPanelContext?.panelId === panel.id;

  const playerActions = usePlayerActions();

  const popupState = usePopupState({
    variant: "popover",
    popupId: `panel-${panel.id}-menu`,
  });

  function createVisualizationChangeHandler(tab: VisualizationType) {
    return function handleVisualizationChange(): void {
      playerActions.chooseVisualization(panel, tab);
    };
  }

  function createClearTopicHandler(panel: InitializedPanel) {
    return function handleClearTopic(): void {
      playerActions.clearPanelTopic(panel);
    };
  }

  function createSplitHandler(orientation: SplitOrientation) {
    return function handleSplit(): void {
      playerActions.splitPanel(panel, orientation);
    };
  }

  function handleClose(): void {
    playerActions.closePanel(panel);
  }

  function handleToggleControls(): void {
    playerActions.togglePanelControls(panel);
  }

  const supportedVisualizations = getSupportedVisualizations(
    panel,
    playerTopicsQuery.data,
  );

  function renderMenuVisItem(text: string, value: VisualizationType) {
    invariant(
      checkIsPanelInitialized(panel),
      "Can only render vis items for initialized panel panel",
    );

    const isCurrent = panel.visualization === value;

    const visualizationChangeHandlerProps = getEventHandlerProps(
      "onClick",
      supportedVisualizations[value] && createVisualizationChangeHandler(value),
    );

    return (
      <MenuItem {...visualizationChangeHandlerProps}>
        {isCurrent && (
          <ListItemIcon>
            <Check />
          </ListItemIcon>
        )}
        <ListItemText inset={!isCurrent}>{text}</ListItemText>
      </MenuItem>
    );
  }

  return (
    <Stack
      direction="row"
      spacing={isConstrainedWidth ? 0.5 : 1}
      alignItems="center"
    >
      <Tooltip title="Open panel settings">
        <IconButton {...bindTrigger(popupState)} size="small">
          <MoreVert fontSize={iconFontSize} />
        </IconButton>
      </Tooltip>
      <Menu
        MenuListProps={{
          dense: isConstrainedHeight,
        }}
        {...bindPopover(popupState)}
        slotProps={{
          paper: {
            sx: {
              width: 300,
              "& .MuiDivider-root": {
                my: 1,
              },
            },
          },
        }}
      >
        {checkIsPanelInitialized(panel) &&
          staticConditionalMenuChildren(
            <MenuItem onClick={createClearTopicHandler(panel)}>
              <ListItemIcon>
                <FileTreeOutline />
              </ListItemIcon>
              <ListItemText>Choose new topic</ListItemText>
            </MenuItem>,
            <Divider component="li" />,
            renderMenuVisItem("Timeline", VisualizationType.Timeline),
            renderMenuVisItem("Chart", VisualizationType.Chart),
            renderMenuVisItem("Image", VisualizationType.Image),
            renderMenuVisItem("Map", VisualizationType.Map),
            renderMenuVisItem("3D", VisualizationType.ThreeD),
            <Divider component="li" />,
          )}
        <MenuItem onClick={createSplitHandler(SplitOrientation.Vertical)}>
          <ListItemIcon>
            <VerticalSplit />
          </ListItemIcon>
          <ListItemText>Split panel right</ListItemText>
        </MenuItem>
        <MenuItem onClick={createSplitHandler(SplitOrientation.Horizontal)}>
          <ListItemIcon>
            <HorizontalSplit />
          </ListItemIcon>
          <ListItemText>Split panel down</ListItemText>
        </MenuItem>
        <Divider component="li" />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Close />
          </ListItemIcon>
          <ListItemText>Close panel</ListItemText>
        </MenuItem>
      </Menu>
      {panel.title != null && (
        <Typography
          fontWeight="bold"
          noWrap
          variant={isConstrainedHeight ? "body2" : "body1"}
        >
          {panel.title}
        </Typography>
      )}
      <Stack
        direction="row"
        spacing={1}
        sx={{ ml: "auto", alignItems: "center" }}
      >
        {controlledPanelContext !== undefined && (
          <Tooltip title="Panel controls">
            <IconButton size="small" onClick={handleToggleControls}>
              <SettingsApplications
                color={isVisible ? "primary" : undefined}
                fontSize={iconFontSize}
              />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
    </Stack>
  );
}
