/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface Log {
  locked: boolean;
  lockedBy: string | null;
  lockedAt: Date | null;
  lockToken: string | null;
  id: string;
  createdAt: Date;
  updatedAt: Date | null;
  deletedAt: Date | null;
  createdBy: string | null;
  updatedBy: string | null;
  deletedBy: string | null;
  groupId: string;
  name: string;
  startTime: bigint | null;
  endTime: bigint | null;
  duration: bigint | null;
  baseTimestamp: bigint | null;
  recordSize: number;
  recordCount: number;
  objectSize: number;
  objectCount: number;
  note: string | null;
  context: object | null;
  defaultWorkflowId: string | null;
}

export function LogFromJSON(json: any): Log {
  return LogFromJSONTyped(json);
}

export function LogFromJSONTyped(json: any): Log {
  if (json == null) {
    return json;
  }

  return {
    locked: json["locked"],
    lockedBy: json["locked_by"],
    lockedAt:
      json["locked_at"] == null
        ? json["locked_at"]
        : new Date(json["locked_at"]),
    lockToken: json["lock_token"],
    id: json["id"],
    createdAt: new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] == null
        ? json["updated_at"]
        : new Date(json["updated_at"]),
    deletedAt:
      json["deleted_at"] == null
        ? json["deleted_at"]
        : new Date(json["deleted_at"]),
    createdBy: json["created_by"],
    updatedBy: json["updated_by"],
    deletedBy: json["deleted_by"],
    groupId: json["group_id"],
    name: json["name"],
    startTime:
      json["start_time"] == null
        ? json["start_time"]
        : BigInt(json["start_time"]),
    endTime:
      json["end_time"] == null ? json["end_time"] : BigInt(json["end_time"]),
    duration:
      json["duration"] == null ? json["duration"] : BigInt(json["duration"]),
    baseTimestamp:
      json["base_timestamp"] == null
        ? json["base_timestamp"]
        : BigInt(json["base_timestamp"]),
    recordSize: json["record_size"],
    recordCount: json["record_count"],
    objectSize: json["object_size"],
    objectCount: json["object_count"],
    note: json["note"],
    context: json["context"],
    defaultWorkflowId: json["default_workflow_id"],
  };
}

export function LogToJSON(value: Log | null | undefined): any {
  if (value == null) {
    return value;
  }

  return {
    locked: value.locked,
    locked_by: value.lockedBy,
    locked_at: value.lockedAt === null ? null : value.lockedAt.toISOString(),
    lock_token: value.lockToken,
    id: value.id,
    created_at: value.createdAt.toISOString(),
    updated_at: value.updatedAt === null ? null : value.updatedAt.toISOString(),
    deleted_at: value.deletedAt === null ? null : value.deletedAt.toISOString(),
    created_by: value.createdBy,
    updated_by: value.updatedBy,
    deleted_by: value.deletedBy,
    group_id: value.groupId,
    name: value.name,
    start_time: value.startTime === null ? null : value.startTime.toString(),
    end_time: value.endTime === null ? null : value.endTime.toString(),
    duration: value.duration === null ? null : value.duration.toString(),
    base_timestamp:
      value.baseTimestamp === null ? null : value.baseTimestamp.toString(),
    record_size: value.recordSize,
    record_count: value.recordCount,
    object_size: value.objectSize,
    object_count: value.objectCount,
    note: value.note,
    context: value.context,
    default_workflow_id: value.defaultWorkflowId,
  };
}
