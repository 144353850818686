import type { FieldValues, Path } from "react-hook-form";
import type { ResourceSelectFieldProps } from "~/components/Form";
import { ResourceSelectField } from "~/components/Form";
import {
  useObjectStoreQueryOptionsFactory,
  useObjectStoresQueryOptionsFactory,
} from "../api";
import type { ObjectStore } from "../sdk";
import { objectStoreVersionHistories, useLqsVersion } from "../versioning";

export function ObjectStoreSelect<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>(props: ResourceSelectFieldProps<TFieldValues, TName>) {
  const createObjectStoresQueryOptions = useObjectStoresQueryOptionsFactory();
  const createObjectStoreQueryOptions = useObjectStoreQueryOptionsFactory();

  const lqsVersion = useLqsVersion();
  let versionedProps;
  if (
    lqsVersion.checkStatus(objectStoreVersionHistories.model.name) !==
    "unavailable"
  ) {
    versionedProps = {
      createListQueryOptions: (text: string) =>
        createObjectStoresQueryOptions({
          nameLike: text,
          limit: 5,
          sort: "asc",
          order: "name",
        }),
      selectOption: (objectStore: ObjectStore) => ({
        value: objectStore.id,
        label: objectStore.name,
      }),
    };
  } else {
    versionedProps = {
      createListQueryOptions: (text: string) =>
        createObjectStoresQueryOptions({
          bucketNameLike: text,
          limit: 5,
          sort: "asc",
          order: "bucket_name",
        }),
      selectOption: (objectStore: ObjectStore) => ({
        value: objectStore.id,
        label: objectStore.bucketName,
      }),
    };
  }

  return (
    <ResourceSelectField
      {...props}
      {...versionedProps}
      createFetchQueryOptions={createObjectStoreQueryOptions}
    />
  );
}
