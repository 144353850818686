import React from "react";
import { DoNotDisturb } from "@mui/icons-material";
import {
  Button,
  Divider,
  dividerClasses,
  Stack,
  Typography,
} from "@mui/material";
import * as z from "zod";
import { TextField, useStudioForm } from "~/components/Form";
import { Loading } from "~/components/Loading";
import { ErrorMessage } from "~/components/error-message";
import { optionalText } from "~/domain/common";
import { SidebarHeader } from "~/layout";
import { invariant } from "~/lib/invariant";
import type { Panel } from "~/player";
import {
  ChartVisualizationControls,
  checkIsPanelInitialized,
  editPanelTitle,
  getPrimaryTopicDescriptor,
  ImageControls,
  MapVisualizationControls,
  PanelProvider,
  TagsControl,
  ThreeDVisualizationControls,
  TopicName,
  useControlledPanelContext,
  usePanelLayoutContext,
  usePlaybackSource,
  usePlayerTopics,
  VisualizationType,
} from "~/player";

export function PanelControlsSidebar() {
  const controlledPanelContext = useControlledPanelContext();

  const { panels } = usePanelLayoutContext();

  const controlledPanel = panels.find(
    (panel) => panel.id === controlledPanelContext.panelId,
  );
  invariant(controlledPanel != null, "Expected to find panel");

  const playbackSource = usePlaybackSource();

  const playerTopicsQuery = usePlayerTopics();

  let content;
  if (playbackSource.isLoading || playerTopicsQuery.isLoading) {
    content = <Loading type="circular" />;
  } else if (playerTopicsQuery.isError) {
    content = <ErrorMessage>Error loading topics</ErrorMessage>;
  } else if (!checkIsPanelInitialized(controlledPanel)) {
    content = (
      <>
        <PanelTitleField panel={controlledPanel} />
        <Divider />
      </>
    );
  } else {
    const topicDescriptor = getPrimaryTopicDescriptor(controlledPanel);

    const topic = playerTopicsQuery.data.find(
      (topic) => topic.name === topicDescriptor.name,
    );

    if (topic == null) {
      content = (
        <ErrorMessage icon={<DoNotDisturb fontSize="large" color="error" />}>
          <TopicName monospace>{topicDescriptor.name}</TopicName> is not in this
          log
        </ErrorMessage>
      );
    } else {
      let controls = null;
      switch (controlledPanel.visualization) {
        case VisualizationType.Chart: {
          controls = (
            <ChartVisualizationControls panel={controlledPanel} topic={topic} />
          );

          break;
        }
        case VisualizationType.ThreeD: {
          controls = (
            <ThreeDVisualizationControls
              panel={controlledPanel}
              topic={topic}
            />
          );

          break;
        }
        case VisualizationType.Image: {
          controls = <ImageControls panel={controlledPanel} topic={topic} />;

          break;
        }
        case VisualizationType.Map: {
          controls = (
            <MapVisualizationControls panel={controlledPanel} topic={topic} />
          );

          break;
        }
      }

      content = (
        <>
          <PanelTitleField panel={controlledPanel} />
          <TagsControl topic={topic} />
          <Divider />
          {controls}
        </>
      );
    }
  }

  return (
    <>
      <SidebarHeader title="Panel Controls" />
      <Stack
        sx={{
          [`& .${dividerClasses.root}`]: {
            my: 2,
          },
        }}
      >
        <PanelProvider panel={controlledPanel}>{content}</PanelProvider>
      </Stack>
    </>
  );
}

function PanelTitleField({ panel }: { panel: Panel }) {
  const { dispatch } = usePanelLayoutContext();

  const {
    control,
    handleSubmit,
    reset,
    formState: { dirtyFields },
  } = useStudioForm({
    schema: z.object({
      title: optionalText,
    }),
    values: {
      title: panel.title,
    },
    onSubmit(values) {
      dispatch(
        editPanelTitle({
          panelId: panel.id,
          title: values.title,
        }),
      );
    },
  });

  function handleCancel(): void {
    reset({ title: panel.title });
  }

  return (
    <form onSubmit={handleSubmit} noValidate>
      <TextField
        control={control}
        name="title"
        label="Panel Title"
        helperText={
          dirtyFields.title ? (
            <>
              Press{" "}
              <Typography
                component="span"
                variant="inherit"
                sx={{ fontWeight: "bold" }}
              >
                Save
              </Typography>{" "}
              to update title
            </>
          ) : undefined
        }
      />
      <Stack
        direction="row"
        spacing={1}
        sx={{ marginBlockStart: 0.5, marginBlockEnd: 2 }}
      >
        <Button type="submit" color="primary" variant="outlined" size="small">
          Save
        </Button>
        <Button
          color="secondary"
          variant="text"
          size="small"
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </Stack>
    </form>
  );
}
