import React from "react";
import { Divider } from "@mui/material";
import type { Topic } from "~/lqs";
import { useInferenceFrameControls } from "../../../inference";
import type { ImagePanel } from "../../../panels";
import { getPrimaryTopicDescriptor } from "../../../panels";
import { ImageTransformControls } from "./image-transform-controls";

export function ImageControls({
  panel,
  topic,
}: {
  panel: ImagePanel;
  topic: Topic;
}) {
  const inferenceFrameControls = useInferenceFrameControls({ strict: false });

  return (
    <>
      <ImageTransformControls
        panel={panel}
        flipDirection={getPrimaryTopicDescriptor(panel).flip}
      />
      {inferenceFrameControls != null && (
        <>
          <Divider />
          {inferenceFrameControls}
        </>
      )}
    </>
  );
}
