import { useState } from "react";
import { Clear } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { JsonTree } from "~/components/JsonTree";
import { ErrorMessage } from "~/components/error-message";
import { invariant } from "~/lib/invariant";
import type { Topic } from "~/lqs";
import { usePlayerActions } from "../../actions";
import type { ChartPanel } from "../../panels";
import {
  getPrimaryTopicDescriptor,
  MAX_CHART_FIELDS,
  VisualizationType,
} from "../../panels";
import { useRecords } from "../../record-store";
import { useVisualizationStoreParams } from "../context";
import lookupFieldUnit from "./lookup-field-unit";
import { getFieldStroke } from "./utils";

export function ChartVisualizationControls({
  panel,
  topic,
}: {
  panel: ChartPanel;
  topic: Topic;
}) {
  const { fields } = getPrimaryTopicDescriptor(panel);

  const [adding, setAdding] = useState(fields.length === 0);

  const playerActions = usePlayerActions();

  const { timestepOverride } = useVisualizationStoreParams(
    VisualizationType.Chart,
  );
  const mostRecentRecordRequest = useRecords({
    recordType: "default",
    topic,
    timestepOverride,
    count: 1,
    limit: 1,
  });

  function createFieldRemovalHandler(field: string) {
    return function handleRemoveField(): void {
      playerActions.removeChartField(panel, field);
    };
  }

  function toggleAdding(): void {
    setAdding(!adding);
  }

  let addingSection = null;
  if (adding) {
    let recordTree;
    if (mostRecentRecordRequest.snapshot.status === "pending") {
      recordTree = (
        <>
          <Typography>Loading most recent record</Typography>
          <LinearProgress />
        </>
      );
    } else if (mostRecentRecordRequest.snapshot.status === "rejected") {
      recordTree = (
        <ErrorMessage>Unable to load most recent record</ErrorMessage>
      );
    } else {
      const mostRecentRecord = mostRecentRecordRequest.snapshot.value.at(0);

      function handleAddField(field: string): void {
        invariant(mostRecentRecord != null, "Most recent record not available");

        playerActions.addChartField(panel, field, mostRecentRecord.data);
      }

      recordTree = (
        <Box sx={{ position: "relative" }}>
          <JsonTree
            src={mostRecentRecord?.data}
            onSelect={handleAddField}
            itemDisabled={
              fields.length === MAX_CHART_FIELDS
                ? true
                : (_, value, path) =>
                    typeof value !== "number" || fields.includes(path)
            }
          />
          {mostRecentRecordRequest.isPlaceholder && (
            <Backdrop
              open
              sx={{
                position: "absolute",
                flexDirection: "column",
                justifyContent: "start",
              }}
            >
              <Stack
                sx={{
                  alignItems: "center",
                  marginBlockStart: 10,
                  padding: 1,
                  backdropFilter: "blur(10px)",
                }}
              >
                <CircularProgress />
                <Typography variant="h5" component="p">
                  Loading most recent record
                </Typography>
              </Stack>
            </Backdrop>
          )}
        </Box>
      );
    }

    addingSection = (
      <>
        <Typography variant="h6" component="p" sx={{ marginBlockStart: 2 }}>
          Add Fields
        </Typography>
        <Typography variant="body2" sx={{ marginBlockEnd: 1 }}>
          Select up to 3 number fields to plot on the chart
        </Typography>
        {recordTree}
      </>
    );
  }

  return (
    <>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          backdropFilter: "blur(15px)",
          // Padding lets the backdrop filter extend around the content instead
          // of being right up against it.
          padding: 1,
          // Negative margin counteracts the padding to make the content line
          // up with other sections again.
          margin: -1,
        }}
      >
        <Typography variant="h6" component="p">
          Fields
        </Typography>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {fields.length} / {MAX_CHART_FIELDS} selected
        </Typography>
        <List>
          {fields.map((field, index) => {
            const units = lookupFieldUnit(topic.typeName, field);

            return (
              <ListItem key={field} disablePadding>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography>
                      {field}
                      {units != null && ` (${units})`}
                    </Typography>
                  }
                  secondary={
                    <Box
                      sx={{
                        border: "4px solid",
                        borderColor: getFieldStroke(index),
                        borderRadius: 1,
                        width: 75,
                      }}
                    />
                  }
                />
                <ListItemSecondaryAction>
                  <Tooltip title="Remove">
                    <IconButton onClick={createFieldRemovalHandler(field)}>
                      <Clear color="error" />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
        <Button
          sx={{ marginBlockStart: 1 }}
          variant="outlined"
          fullWidth
          onClick={toggleAdding}
        >
          {adding ? "Hide Fields" : "Add Fields"}
        </Button>
      </Box>
      {addingSection}
    </>
  );
}
