import type React from "react";
import {
  InferenceFrameControls,
  InferenceFrameControlsProvider,
} from "~/player";

export function StudioPlayerInferenceProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <InferenceFrameControlsProvider element={<InferenceFrameControls />}>
      {children}
    </InferenceFrameControlsProvider>
  );
}
