import type { StrictOmit } from "ts-essentials";
import * as z from "zod";
import type { SchemaShape, TableSchemaShape } from "~/components/Form";
import {
  boolean,
  commonResourceFilterSchemas,
  filterBoolean,
  filterText,
  filterUuid,
  optionalObject,
  optionalText,
  requiredText,
} from "~/domain/common";
import type {
  ListObjectStoresRequest,
  ObjectStoreCreateRequest,
  ObjectStoreUpdateRequest,
} from "~/lqs";

export const listObjectStoresSchema = z.object({
  id: filterUuid,
  bucketName: filterText,
  bucketNameLike: filterText,
  name: filterText,
  nameLike: filterText,
  accessKeyId: filterText,
  regionName: filterText,
  endpointUrl: filterText,
  note: filterText,
  noteLike: filterText,
  disabled: filterBoolean,
  ...commonResourceFilterSchemas,
} satisfies TableSchemaShape<
  StrictOmit<ListObjectStoresRequest, "includeCount">
>);

export const createObjectStoreSchema = z.object({
  bucketName: requiredText,
  name: requiredText,
  keyPrefix: optionalText,
  accessKeyId: optionalText,
  secretAccessKey: optionalText,
  regionName: optionalText,
  endpointUrl: optionalText,
  _default: boolean,
  readOnly: boolean,
  managed: boolean,
  disabled: boolean,
  note: optionalText,
  context: optionalObject,
} satisfies SchemaShape<ObjectStoreCreateRequest>);

// TODO: Add `secretAccessKey`
export const editObjectStoreSchema = z.object({
  bucketName: requiredText,
  name: requiredText,
  keyPrefix: optionalText,
  accessKeyId: optionalText,
  regionName: optionalText,
  endpointUrl: optionalText,
  _default: boolean,
  readOnly: boolean,
  managed: boolean,
  disabled: boolean,
  note: optionalText,
  context: optionalObject,
} satisfies SchemaShape<
  StrictOmit<ObjectStoreUpdateRequest, "secretAccessKey">
>);
