import { useId, useLayoutEffect, useState } from "react";
import type { Maybe } from "~/types";
import { getEventHandlerProps } from "~/utils/get-event-handler-props";
import { usePlayerActions } from "../../actions";
import type { PointerContainerProps, PointerLocation } from "../../hooks";
import { usePointerLocation } from "../../hooks";
import { supportsClipInset } from "../../inference";
import type { ImagePanel } from "../../panels";
import { checkIsImagePanelWithInference } from "../../panels";
import type { ImageFrame } from "./types";

export function useImageSliderController({
  panel,
  imageFrame,
}: {
  panel: ImagePanel;
  imageFrame: Maybe<ImageFrame>;
}): {
  enabled: boolean;
  baseImageId: string;
  pointerContainerProps: PointerContainerProps;
} {
  const inferenceType = imageFrame?.inferenceFrames?.type;

  const baseImageId = useId();

  const [baseImageDimensions, setBaseImageDimensions] = useState<{
    naturalWidth: number;
    naturalHeight: number;
  } | null>(null);

  const pointerChangeHandlerProps = getEventHandlerProps(
    "onChange",
    inferenceType != null &&
      supportsClipInset(inferenceType) &&
      checkIsImagePanelWithInference(panel) &&
      function handleChange(pointerLocation: PointerLocation): void {
        if (baseImageDimensions === null) {
          // This shouldn't happen because, if this callback is enabled, then the
          // layout effect below should've already run and caused the dimensions
          // to be stored
          return;
        }

        playerActions.clipInferenceImage(
          panel,
          pointerLocation,
          baseImageDimensions,
        );
      },
  );

  useLayoutEffect(
    function storeImageDimensions() {
      if (baseImageDimensions !== null) {
        // Only going to measure once and assume all other images from this
        // topic will have at least the same aspect ratio
        return;
      }

      if (pointerChangeHandlerProps.disabled) {
        // No need to queue an extra re-render if we're not going to use the
        // information yet
        return;
      }

      const inferenceImageElement = document.getElementById(baseImageId);
      if (inferenceImageElement === null) {
        // The <img> element should be in the DOM but just bail out in
        // case it isn't
        return;
      }

      const { naturalWidth, naturalHeight } =
        inferenceImageElement as HTMLImageElement;

      setBaseImageDimensions({ naturalWidth, naturalHeight });
    },
    [baseImageDimensions, pointerChangeHandlerProps.disabled, baseImageId],
  );

  const playerActions = usePlayerActions();

  const pointerContainerProps = usePointerLocation(pointerChangeHandlerProps);

  return {
    enabled: !pointerChangeHandlerProps.disabled,
    baseImageId,
    pointerContainerProps,
  };
}
