import React from "react";
import { styled } from "@mui/material";
import { createSafeContext } from "~/contexts";
import { invariant } from "~/lib/invariant";
import { SplitOrientation } from "./constants";
import { usePanelLayoutContext } from "./provider";
import { resizeNode } from "./reducer";
import type {
  LayoutContainerNode,
  LayoutNode,
  LayoutPanelNode,
  Panel,
} from "./types";
import { useSplitNodes } from "./use-split-nodes";

const rootStyles = {
  height: "100%",
  width: "100%",
  position: "relative",
  boxSizing: "border-box",
} as const;

const PanelRoot = styled("div")(rootStyles);

const ClassNames = {
  Splitter: "container-splitter",
} as const;

const Vars = {
  SplitterSize: "--spl-size",
} as const;

const ContainerRoot = styled("div")({
  ...rootStyles,
  display: "flex",
  overflow: "hidden",
  [`& > .${ClassNames.Splitter}`]: {
    // Use z-index so the splitter's ::before pseudo element sits over top of
    // the subsequent sibling node
    zIndex: 1,
    backgroundColor: "darkgrey",
    flex: "none",
    position: "relative",
    "&::before": {
      content: '" "',
      position: "absolute",
      width: "100%",
      height: "100%",
      // By default, the ::before pseudo element will slightly extend outside
      // of the splitter in the direction the user can resize, making it easier
      // to resize
      [Vars.SplitterSize]: "300%",
      // When the user has any coarse pointer or any input method without
      // hovering capabilities, e.g. their finger on a touchscreen, increase
      // the ::before size to make it that much easier to resize
      [`@media (any-pointer:coarse),(any-hover:none)`]: {
        [Vars.SplitterSize]: "500%",
      },
    },
  },
  [`&[data-orientation="${SplitOrientation.Horizontal}"]`]: {
    flexDirection: "column",
    [`& > .${ClassNames.Splitter}`]: {
      width: "100%",
      height: "3px",
      cursor: "ns-resize",
      "&::before": {
        height: `var(${Vars.SplitterSize})`,
        top: "50%",
        translate: "0 -50%",
      },
    },
  },
  [`&[data-orientation="${SplitOrientation.Vertical}"]`]: {
    flexDirection: "row",
    [`& > .${ClassNames.Splitter}`]: {
      width: "3px",
      height: "100%",
      cursor: "ew-resize",
      "&::before": {
        width: `var(${Vars.SplitterSize})`,
        left: "50%",
        translate: "-50% 0",
      },
    },
  },
});

export function Panels({ element }: { element: React.ReactNode }) {
  const { layout: rootNode } = usePanelLayoutContext();

  return <Node node={rootNode} element={element} />;
}

function Node({
  node,
  element,
}: {
  node: LayoutNode;
  element: React.ReactNode;
}) {
  if ("children" in node) {
    return <Container node={node} element={element} />;
  } else {
    return <Panel node={node} element={element} />;
  }
}

function Container({
  node,
  element,
}: {
  node: LayoutContainerNode;
  element: React.ReactNode;
}) {
  const { dispatch } = usePanelLayoutContext();

  const splitPanels = useSplitNodes({
    orientation: node.orientation,
    firstNodeFlex: node.children[0].size,
    secondNodeFlex: node.children[1].size,
    onResizeStop(firstNodeFlex, secondNodeFlex) {
      dispatch(
        resizeNode({ nodeId: node.children[0].id, flex: firstNodeFlex }),
      );
      dispatch(
        resizeNode({ nodeId: node.children[1].id, flex: secondNodeFlex }),
      );
    },
  });

  return (
    <ContainerRoot {...splitPanels.containerProps}>
      <div {...splitPanels.firstNodeProps}>
        <Node node={node.children[0]} element={element} />
      </div>
      <div className={ClassNames.Splitter} {...splitPanels.splitterProps} />
      <div {...splitPanels.secondNodeProps}>
        <Node node={node.children[1]} element={element} />
      </div>
    </ContainerRoot>
  );
}

export const [usePanelContext, PanelContext] =
  createSafeContext<Panel>("Panel");

export function PanelProvider({
  panel,
  children,
}: {
  panel: Panel;
  children: React.ReactNode;
}) {
  return (
    <PanelContext.Provider value={panel}>{children}</PanelContext.Provider>
  );
}

function Panel({
  node,
  element,
}: {
  node: LayoutPanelNode;
  element: React.ReactNode;
}) {
  const { panels } = usePanelLayoutContext();

  const panel = panels.find((panel) => panel.id === node.panelId);

  invariant(panel != null, `No panel found with ID ${node.panelId}`);

  return (
    <PanelProvider panel={panel}>
      <PanelRoot>{element}</PanelRoot>
    </PanelProvider>
  );
}
