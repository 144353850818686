import type React from "react";
import type { UseQueryResult } from "@tanstack/react-query";
import type { Draft, WritableDraft } from "immer";
import type { ImmerReducer } from "use-immer";
import { useImmerReducer } from "use-immer";
import { invariant } from "~/lib/invariant";
import { clamp, get } from "~/lib/std";
import type { Topic } from "~/lqs";
import { assertNever } from "~/utils";
import type { PointerLocation } from "../hooks";
import type { PlayerRecord } from "../record-store";
import type { SplitOrientation } from "./constants";
import {
  ClipInsetSide,
  FlipDirection,
  IMAGE_ROTATION_STEP_MAGNITUDE_DEG,
  MAX_CHART_FIELDS,
  MAX_IMAGE_BRIGHTNESS_PCT,
  MAX_IMAGE_CONTRAST_PCT,
  RotationDirection,
  VisualizationType,
} from "./constants";
import { deserializePanels } from "./serialization";
import type { VisualizationFilter } from "./topic-config";
import { getDefaultVisualization } from "./topic-config";
import type {
  ChartPanel,
  ChartTopicDescriptor,
  ImagePanel,
  ImagePanelWithInference,
  ImageTopicDescriptor,
  InferenceTopicDescriptor,
  InitializedPanel,
  LayoutContainerNode,
  LayoutNode,
  LayoutPanelNode,
  LayoutProfileDescriptor,
  MapPanel,
  MapTopicDescriptor,
  Panel,
  PointCloudTopicDescriptor,
  ThreeDPanel,
  TimelineTopicDescriptor,
  UninitializedPanel,
} from "./types";
import {
  calculateRotationQuadrant,
  checkIsPanelInitialized,
  getTopicContextDisplayName,
  layoutTreeIterable,
} from "./utils";

export type AsyncDefaultProfile = Pick<
  UseQueryResult<LayoutProfileDescriptor | null>,
  "status" | "data"
>;

interface OverridableTopicStateRegistry {
  [VisualizationType.Image]: Pick<ImageTopicDescriptor, "colorize">;
}

export type TopicInitializationOverrider = (
  defaultVisualization: keyof OverridableTopicStateRegistry,
  sourceTopicName: Topic["name"],
) =>
  | Partial<OverridableTopicStateRegistry[keyof OverridableTopicStateRegistry]>
  | undefined
  | void;

interface PanelLayoutActionRegistry {
  /**
   * Split a panel, creating a new, uninitialized sibling panel. The orientation
   * describes if the sibling should be to the right of or below the base panel.
   */
  "split-panel": {
    panelId: LayoutPanelNode["id"];
    orientation: SplitOrientation;
  };
  /**
   * Remove a panel from the tree entirely. If the panel is the only one in the
   * tree, a new, uninitialized panel replaces it.
   */
  "remove-panel": {
    panelId: LayoutPanelNode["id"];
  };
  /**
   * Resize a panel or container. The flex describes its relative size compared
   * to its sibling.
   */
  "resize-node": {
    nodeId: LayoutNode["id"];
    flex: number;
  };
  /**
   * Load a layout, overwriting the existing layout completely. Useful to load
   * layout profiles kept in a user's web storage.
   */
  "load-layout": {
    profile: LayoutProfileDescriptor;
  };
  /**
   * Edit or clear this panel's title.
   */
  "edit-panel-title": {
    panelId: Panel["id"];
    title: string | null;
  };
  "set-topic-search-filter": {
    panelId: UninitializedPanel["id"];
    search: string | null;
  };
  "set-topic-visualization-filter": {
    panelId: UninitializedPanel["id"];
    filterName: VisualizationFilter | null;
  };
  /**
   * Initialize the panel using the given topic. The panel's
   * visualization-related fields will be given default values. The initial
   * visualization depends on the topic's message type, if any:
   *  - If the message type supports image data, the panel will be initialized
   *    to the "image" visualization
   *  - If the message type supports GPS data, the panel will be initialized to
   *    the "map" visualization
   *  - For all other message types, the panel will be initialized to the
   *    "timeline" visualization
   *
   * Invariants:
   *  - Panel must be uninitialized
   */
  "select-topic": {
    panelId: UninitializedPanel["id"];
    topic: Topic;
  };
  /**
   * Adjust the image's brightness, given as a percentage.
   *
   * Invariants:
   *  - Panel must be initialized
   *  - Brightness must be in the range [0, {@link MAX_IMAGE_BRIGHTNESS_PCT}]
   */
  "adjust-image-brightness": {
    panelId: ImagePanel["id"];
    brightness: number;
  };
  /**
   * Adjust the image's contrast, given as a percentage.
   *
   * Invariants:
   *  - Panel must be initialized
   *  - Contrast must be in the range [0, {@link MAX_IMAGE_CONTRAST_PCT}]
   */
  "adjust-image-contrast": {
    panelId: ImagePanel["id"];
    contrast: number;
  };
  /**
   * Toggle image colorization
   *
   * Invariants:
   *  - Panel must be initialized
   */
  "toggle-image-colorization": {
    panelId: ImagePanel["id"];
    colorize: boolean;
  };
  /**
   * Rotate the displayed image 90 degrees in the specified direction
   *
   * Invariants:
   *  - Panel must be initialized
   */
  "rotate-image": {
    panelId: ImagePanel["id"];
    direction: RotationDirection;
  };
  /**
   * Set or clear the direction in which the image should be flipped.
   *
   * Invariants:
   *   - Panel must be initialized
   */
  "set-image-flip-direction": {
    panelId: ImagePanel["id"];
    flipDirection: FlipDirection | null;
  };
  /**
   * Set or clear the inference topic to be visualized in this panel alongside
   * the main topic.
   *
   * Invariants:
   *   - Panel must be initialized
   */
  "set-inference-topic": {
    panelId: ImagePanel["id"];
    inferenceTopic: Topic | null;
  };
  "toggle-inference-transform-lock": {
    panelId: ImagePanelWithInference["id"];
    lock: boolean;
  };
  /**
   * Adds a field to be plotted in the chart visualization. The field can be
   * top-level or nested and must be available in the `data` payload
   * argument. The field must follow the syntax for lodash's `get` method. The
   * value at the given field should be a numeric type.
   *
   * There are some circumstances under which the field will not be added,
   * though these circumstances do not represent an error:
   *  - Maximum number of fields already added
   *  - Field has already been added
   *  - Field value is not a numeric type
   *
   * Invariants:
   *  - Panel must be initialized
   */
  "add-chart-field": {
    panelId: ChartPanel["id"];
    field: string;
    data: PlayerRecord<"default">["data"];
  };
  /**
   * Removes the given field from the panel's chart visualization fields.
   *
   * Invariants:
   *  - Panel must be initialized
   *  - Field must have previously been added
   */
  "remove-chart-field": {
    panelId: ChartPanel["id"];
    field: string;
  };
  /**
   * Selects the provided tag for the panel, unless that tag is already
   * selected, in which case the tag will be un-selected.
   *
   * Invariants:
   *   - Panel must be initialized
   */
  "select-tag": {
    panelId: InitializedPanel["id"];
    tag: string;
  };
  /**
   * Change whether a detection-type inference output's bounding boxes should be
   * drawn.
   *
   * Invariants:
   *   - Panel must be initialized
   */
  "show-detection-bounding-boxes": {
    panelId: ImagePanelWithInference["id"];
    showDetectionBoundingBoxes: boolean;
  };
  /**
   * Change whether a detection-type inference output's detection class names
   * should be displayed.
   *
   * Invariants:
   *   - Panel must be initialized
   */
  "show-detection-class-names": {
    panelId: ImagePanelWithInference["id"];
    showDetectionClassNames: boolean;
  };
  /**
   * Change the visibility for this class of detected objects.
   *
   * Invariants:
   *   - Panel must be initialized
   */
  "change-object-class-visibility": {
    panelId: ImagePanelWithInference["id"];
    className: string;
    hideClass: boolean;
  };
  /**
   * Set the opacity for the segmentation and depth-estimation inference result
   * image overlay.
   *
   * Invariants:
   *   - Panel must be initialized
   *   - Opacity must be in the range [0, 1]
   */
  "set-inference-image-opacity": {
    panelId: ImagePanelWithInference["id"];
    opacity: number;
  };
  /**
   * Toggle inference image colorization.
   *
   * Invariants:
   *   - Panel must be initialized
   */
  "toggle-inference-image-colorization": {
    panelId: ImagePanelWithInference["id"];
    colorize: boolean;
  };
  /**
   * Set the new inference image clip inset taking into consideration
   * transformations.
   *
   * Invariants:
   *   - Panel must be initialized
   */
  "set-inference-image-clip-inset": {
    panelId: ImagePanelWithInference["id"];
    pointerLocation: PointerLocation;
    baseImageDimensions: { naturalWidth: number; naturalHeight: number };
  };
  /**
   * Set the size of individual points in the 3D point cloud.
   *
   * Invariants:
   *   - Panel must be initialized
   *   - Point size must be in the range [0, 0.1]
   */
  "set-point-cloud-point-size": {
    panelId: ThreeDPanel["id"];
    pointSize: number;
  };
  /**
   * Switches the panel to the given visualization.
   *
   * Invariants:
   *  - Panel must be initialized
   */
  "choose-visualization": {
    panelId: InitializedPanel["id"];
    tab: VisualizationType;
  };
  /**
   * Clears the selected topic for the panel, returning the panel to an
   * uninitialized state.
   *
   * Invariants:
   *  - Panel must be initialized
   */
  "choose-new-topic": {
    panelId: InitializedPanel["id"];
  };
  /**
   * Mark the panel as having been used to initiate an auto-skip playback
   * action. The record will be kept until the panel is closed or its topic
   * is changed. Changing the panel's visualization will not clear the record.
   *
   * Invariants:
   *  - Panel must be initialized
   */
  "record-auto-skip": {
    panelId: InitializedPanel["id"];
  };
  /**
   * Set this panel's supplementary map topic.
   *
   * Invariants:
   *  - Panel must be initialized
   */
  "set-supplementary-map-topic": {
    panelId: MapPanel["id"];
    topic: Topic;
  };
  /**
   * Clear this panel's supplementary map topic.
   *
   * Invariants:
   *  - Panel must be initialized
   *  - A supplementary map topic must already be selected
   */
  "clear-supplementary-map-topic": {
    panelId: MapPanel["id"];
  };
}

export type PanelLayoutAction<
  TActionType extends
    keyof PanelLayoutActionRegistry = keyof PanelLayoutActionRegistry,
> = {
  [ActionType in TActionType]: {
    type: ActionType;
    payload: PanelLayoutActionRegistry[ActionType];
  };
}[TActionType];

function createActionCreator<
  const TActionType extends keyof PanelLayoutActionRegistry,
>(
  type: TActionType,
): (
  payload: PanelLayoutActionRegistry[TActionType],
) => PanelLayoutAction<TActionType> {
  return function createPanelLayoutAction(payload) {
    return {
      type,
      payload,
    };
  };
}

export const splitPanel = createActionCreator("split-panel");

export const removePanel = createActionCreator("remove-panel");

export const resizeNode = createActionCreator("resize-node");

export const loadLayout = createActionCreator("load-layout");

export const editPanelTitle = createActionCreator("edit-panel-title");

export const setTopicSearchFilter = createActionCreator(
  "set-topic-search-filter",
);

export const setTopicVisualizationFilter = createActionCreator(
  "set-topic-visualization-filter",
);

export const selectTopic = createActionCreator("select-topic");

export const adjustImageBrightness = createActionCreator(
  "adjust-image-brightness",
);

export const adjustImageContrast = createActionCreator("adjust-image-contrast");

export const toggleImageColorization = createActionCreator(
  "toggle-image-colorization",
);

export const rotateImage = createActionCreator("rotate-image");

export const setImageFlipDirection = createActionCreator(
  "set-image-flip-direction",
);

export const setInferenceTopic = createActionCreator("set-inference-topic");

export const toggleInferenceTransformLock = createActionCreator(
  "toggle-inference-transform-lock",
);

export const addChartField = createActionCreator("add-chart-field");

export const removeChartField = createActionCreator("remove-chart-field");

export const selectTag = createActionCreator("select-tag");

export const showDetectionBoundingBoxes = createActionCreator(
  "show-detection-bounding-boxes",
);

export const showDetectionClassNames = createActionCreator(
  "show-detection-class-names",
);

export const changeObjectClassVisibility = createActionCreator(
  "change-object-class-visibility",
);

export const setInferenceImageOpacity = createActionCreator(
  "set-inference-image-opacity",
);

export const toggleInferenceImageColorization = createActionCreator(
  "toggle-inference-image-colorization",
);

export const setInferenceImageClipInset = createActionCreator(
  "set-inference-image-clip-inset",
);

export const setPointCloudPointSize = createActionCreator(
  "set-point-cloud-point-size",
);

export const recordAutoSkip = createActionCreator("record-auto-skip");

export const chooseVisualization = createActionCreator("choose-visualization");

export const chooseNewTopic = createActionCreator("choose-new-topic");

export const setSupplementaryMapTopic = createActionCreator(
  "set-supplementary-map-topic",
);

export const clearSupplementaryMapTopic = createActionCreator(
  "clear-supplementary-map-topic",
);

const INITIAL_PANEL_ID = 0;

interface PanelLayoutReducerState {
  layout: LayoutNode;
  panels: ReadonlyArray<Panel>;
  nextId: number;
  canLoadInitialProfile: boolean;
}

const emptyLayoutState: PanelLayoutReducerState = {
  layout: {
    id: INITIAL_PANEL_ID,
    size: 1,
    panelId: INITIAL_PANEL_ID,
  },
  panels: [
    {
      id: INITIAL_PANEL_ID,
      title: null,
      visualization: null,
      topics: [],
      topicSelectionConfig: {
        search: null,
        [VisualizationType.Image]: false,
        [VisualizationType.ThreeD]: false,
        [VisualizationType.Map]: false,
        lastSelected: null,
      },
    },
  ],
  nextId: INITIAL_PANEL_ID + 1,
  canLoadInitialProfile: true,
};

export function usePanelLayoutReducer({
  defaultProfile,
  overrideInitializationState,
}: {
  defaultProfile?: AsyncDefaultProfile;
  overrideInitializationState?: TopicInitializationOverrider;
}): {
  layout: LayoutNode;
  panels: ReadonlyArray<Panel>;
  dispatch: React.Dispatch<PanelLayoutAction>;
} {
  const [reducerState, dispatch] = useImmerReducer(
    createReducer(overrideInitializationState),
    emptyLayoutState,
  );

  if (
    reducerState.canLoadInitialProfile &&
    defaultProfile?.status === "success" &&
    defaultProfile.data != null
  ) {
    dispatch(loadLayout({ profile: defaultProfile.data }));
  }

  return {
    layout: reducerState.layout,
    panels: reducerState.panels,
    dispatch,
  };
}

function createReducer(
  overrider?: TopicInitializationOverrider,
): ImmerReducer<PanelLayoutReducerState, PanelLayoutAction> {
  return function reducer(draft, action) {
    if (action.type === "load-layout") {
      return loadProfile(action.payload.profile);
    }

    draft.canLoadInitialProfile = false;

    switch (action.type) {
      case "split-panel": {
        const panelNode = getLayoutPanelNode(draft, action.payload.panelId);

        const initialParent = getParentContainerNode(draft, panelNode.id);

        const newPanelId = draft.nextId++;
        const newParentId = draft.nextId++;

        const newPanelNode: WritableDraft<LayoutPanelNode> = {
          id: newPanelId,
          size: 0.5,
          panelId: newPanelId,
        };

        const newContainerNode: WritableDraft<LayoutContainerNode> = {
          id: newParentId,
          size: panelNode.size,
          orientation: action.payload.orientation,
          children: [panelNode, newPanelNode],
        };

        panelNode.size = 0.5;

        if (initialParent == null) {
          draft.layout = newContainerNode;
        } else {
          if (panelNode.id === initialParent.children[0].id) {
            initialParent.children[0] = newContainerNode;
          } else {
            initialParent.children[1] = newContainerNode;
          }
        }

        draft.panels.push(createUninitializedPanel(newPanelId));

        return;
      }
      case "remove-panel": {
        const {
          payload: { panelId },
        } = action;

        const panelNode = getLayoutPanelNode(draft, panelId);

        draft.panels = draft.panels.filter(
          (draftPanel) => draftPanel.id !== panelId,
        );

        const parent = getParentContainerNode(draft, panelNode.id);

        if (parent == null) {
          const newPanelId = draft.nextId++;

          draft.layout = {
            id: newPanelId,
            size: 1,
            panelId: newPanelId,
          };
          draft.panels = [createUninitializedPanel(newPanelId)];
        } else {
          const sibling =
            panelNode.id === parent.children[0].id
              ? parent.children[1]
              : parent.children[0];

          const grandparent = getParentContainerNode(draft, parent.id);

          if (grandparent == null) {
            sibling.size = 1;

            draft.layout = sibling;
          } else {
            sibling.size = parent.size;

            if (grandparent.children[0].id === parent.id) {
              grandparent.children[0] = sibling;
            } else {
              grandparent.children[1] = sibling;
            }
          }
        }

        return;
      }
      case "resize-node": {
        const node = getLayoutNode(draft, action.payload.nodeId);

        node.size = action.payload.flex;

        return;
      }
      case "edit-panel-title": {
        const panel = getPanel(draft, action.payload.panelId);

        panel.title = action.payload.title;

        return;
      }
      case "set-topic-search-filter": {
        const panel = getUninitializedPanel(draft, action.payload.panelId);

        panel.topicSelectionConfig.search = action.payload.search;

        return;
      }
      case "set-topic-visualization-filter": {
        const panel = getUninitializedPanel(draft, action.payload.panelId);

        panel.topicSelectionConfig[VisualizationType.Image] = false;
        panel.topicSelectionConfig[VisualizationType.ThreeD] = false;
        panel.topicSelectionConfig[VisualizationType.Map] = false;

        if (action.payload.filterName !== null) {
          panel.topicSelectionConfig[action.payload.filterName] = true;
        }

        return;
      }
      case "select-topic": {
        const panel = getUninitializedPanel(draft, action.payload.panelId);

        initializePanel(panel, action.payload.topic, overrider);

        return;
      }
      case "adjust-image-brightness": {
        const panel = getInitializedPanel(
          draft,
          action.payload.panelId,
          VisualizationType.Image,
        );

        const {
          payload: { brightness },
        } = action;

        invariant(
          0 <= brightness && brightness <= MAX_IMAGE_BRIGHTNESS_PCT,
          `Brightness must be in the range [0, ${MAX_IMAGE_BRIGHTNESS_PCT}]`,
        );

        getBaseImageTopic(panel).brightnessPct = brightness;

        return;
      }
      case "adjust-image-contrast": {
        const panel = getInitializedPanel(
          draft,
          action.payload.panelId,
          VisualizationType.Image,
        );

        const {
          payload: { contrast },
        } = action;

        invariant(
          0 <= contrast && contrast <= MAX_IMAGE_CONTRAST_PCT,
          `Contrast must be in the range [0, ${MAX_IMAGE_CONTRAST_PCT}]`,
        );

        getBaseImageTopic(panel).contrastPct = contrast;

        return;
      }
      case "toggle-image-colorization": {
        const panel = getInitializedPanel(
          draft,
          action.payload.panelId,
          VisualizationType.Image,
        );

        getBaseImageTopic(panel).colorize = action.payload.colorize;

        return;
      }
      case "rotate-image": {
        const panel = getInitializedPanel(
          draft,
          action.payload.panelId,
          VisualizationType.Image,
        );

        const imageTopic = getBaseImageTopic(panel);

        imageTopic.rotationDeg = calculateNewRotationDeg(
          imageTopic.rotationDeg,
          action.payload.direction,
        );

        if (
          hasInferenceTopic(panel) &&
          getInferenceTopic(panel).lockTransforms
        ) {
          const inferenceTopic = getInferenceTopic(panel);

          inferenceTopic.rotationDeg = calculateNewRotationDeg(
            inferenceTopic.rotationDeg,
            action.payload.direction,
          );
        }

        return;
      }
      case "set-image-flip-direction": {
        const panel = getInitializedPanel(
          draft,
          action.payload.panelId,
          VisualizationType.Image,
        );

        const {
          payload: { flipDirection },
        } = action;

        const imageTopic = getBaseImageTopic(panel);

        if (imageTopic.flip === flipDirection) {
          // Probably shouldn't happen but it's a no-op
          return;
        }

        if (
          hasInferenceTopic(panel) &&
          getInferenceTopic(panel).lockTransforms
        ) {
          // Locked output overlay's initial flip direction might not correspond
          // to the image's. For example, the image may be horizontally reflected
          // and the user needed to flip it horizontally and _then_ lock the
          // overlay. In such a situation, the two can never have the same
          // flip direction *but* they'll only be a reflection of each other along
          // a single axis (since reflection along both axes isn't permitted by
          // Studio as it's just a 180-degree rotation).
          //
          // The overlay's stored flip direction and rotation should always
          // represent the transformations necessary to keep the it aligned
          // relative to the image in the same manner as when the user locked
          // the overlay (or when the panel was initialized as locked is the
          // default setting).

          const inferenceTopic = getInferenceTopic(panel);

          const currentFlipDirections = new Set([
            imageTopic.flip,
            inferenceTopic.flip,
          ]);

          const containsOrthogonalFlips =
            currentFlipDirections.has(FlipDirection.X) &&
            currentFlipDirections.has(FlipDirection.Y);

          invariant(
            !containsOrthogonalFlips,
            "Images and segmentations should not be flipped orthogonally",
          );

          if (currentFlipDirections.size === 1) {
            // Both image and segmentations share the same flip direction, so
            // they should remain that way
            inferenceTopic.flip = flipDirection;
          } else if (currentFlipDirections.has(flipDirection)) {
            // By this point, the segmentations are guaranteed to be reflected
            // relative to the other along some axis *and* the payload's flip
            // direction matches the segmentations'. In this case, the
            // segmentations' and image's flip directions need to be swapped.
            inferenceTopic.flip = imageTopic.flip;
          } else {
            // By this point, the payload flip direction is guaranteed to be the
            // x or y axis but doesn't correspond to either the image's or the
            // segmentations' flip directions. Since the segmentations are
            // reflected relative to the image, applying this second flip along
            // the orthogonal axis is equivalent to rotating 180 degrees.
            inferenceTopic.flip = null;
            inferenceTopic.rotationDeg += 2 * IMAGE_ROTATION_STEP_MAGNITUDE_DEG;
          }
        }

        // Image's final flip direction is always the payload's flip direction
        imageTopic.flip = flipDirection;

        return;
      }
      case "set-inference-topic": {
        const panel = getInitializedPanel(
          draft,
          action.payload.panelId,
          VisualizationType.Image,
        );

        panel.topics[1] =
          action.payload.inferenceTopic == null
            ? undefined
            : createInferenceTopic(action.payload.inferenceTopic);

        return;
      }
      case "toggle-inference-transform-lock": {
        const panel = getInitializedPanel(
          draft,
          action.payload.panelId,
          VisualizationType.Image,
        );

        const inferenceTopic = requireInferenceTopic(panel);

        inferenceTopic.lockTransforms = action.payload.lock;

        if (!action.payload.lock) {
          inferenceTopic.rotationDeg = 0;
          inferenceTopic.flip = null;
        }

        return;
      }
      case "add-chart-field": {
        const panel = getInitializedPanel(
          draft,
          action.payload.panelId,
          VisualizationType.Chart,
        );

        const { field, data } = action.payload;

        const {
          topics: [chartTopic],
        } = panel;

        if (chartTopic.fields.length >= MAX_CHART_FIELDS) {
          return;
        }

        if (chartTopic.fields.includes(field)) {
          return;
        }

        if (typeof get(data, field) !== "number") {
          return;
        }

        chartTopic.fields.push(field);

        return;
      }
      case "remove-chart-field": {
        const panel = getInitializedPanel(
          draft,
          action.payload.panelId,
          VisualizationType.Chart,
        );

        const { field } = action.payload;

        const {
          topics: [chartTopic],
        } = panel;

        const index = chartTopic.fields.indexOf(field);

        invariant(index >= 0, `Field not currently selected: "${field}"`);

        chartTopic.fields.splice(index, 1);

        return;
      }
      case "select-tag": {
        const panel = getInitializedPanel(draft, action.payload.panelId);

        if (panel.selectedTag === action.payload.tag) {
          panel.selectedTag = null;
        } else {
          panel.selectedTag = action.payload.tag;
        }

        return;
      }
      case "show-detection-bounding-boxes": {
        const panel = getInitializedPanel(
          draft,
          action.payload.panelId,
          VisualizationType.Image,
        );

        requireInferenceTopic(panel).showDetectionBoundingBoxes =
          action.payload.showDetectionBoundingBoxes;

        return;
      }
      case "show-detection-class-names": {
        const panel = getInitializedPanel(
          draft,
          action.payload.panelId,
          VisualizationType.Image,
        );

        requireInferenceTopic(panel).showDetectionClassNames =
          action.payload.showDetectionClassNames;

        return;
      }
      case "change-object-class-visibility": {
        const panel = getInitializedPanel(
          draft,
          action.payload.panelId,
          VisualizationType.Image,
        );

        const {
          payload: { className, hideClass },
        } = action;

        const inferenceTopic = requireInferenceTopic(panel);

        const index = inferenceTopic.hiddenObjectClassNames.indexOf(className);

        const isCurrentlyHidden = index >= 0;

        if (isCurrentlyHidden && !hideClass) {
          inferenceTopic.hiddenObjectClassNames.splice(index, 1);
        } else if (!isCurrentlyHidden && hideClass) {
          inferenceTopic.hiddenObjectClassNames.push(className);
        }

        return;
      }
      case "set-inference-image-opacity": {
        const panel = getInitializedPanel(
          draft,
          action.payload.panelId,
          VisualizationType.Image,
        );

        const {
          payload: { opacity },
        } = action;

        invariant(
          0 <= opacity && opacity <= 1,
          "Opacity must be in range [0, 1]",
        );

        requireInferenceTopic(panel).opacity = opacity;

        return;
      }
      case "toggle-inference-image-colorization": {
        const panel = getInitializedPanel(
          draft,
          action.payload.panelId,
          VisualizationType.Image,
        );

        requireInferenceTopic(panel).colorize = action.payload.colorize;

        return;
      }
      case "set-inference-image-clip-inset": {
        const panel = getInitializedPanel(
          draft,
          action.payload.panelId,
          VisualizationType.Image,
        );

        const inferenceTopic = requireInferenceTopic(panel);

        const {
          rotationDeg,
          flip,
          clipInset: { side: insetSide },
        } = inferenceTopic;

        // The inset needs to be calculated differently depending on the
        // inset side:
        // 1. For left and right sides, use the x coordinate and width
        // 2. For top and bottom sides, use the y coordinate and height
        // 3. For left and top sides, don't need to invert the percentage
        // 4. For right and bottom sides, need to invert the percentage
        //
        // Inversion is necessary for the right and bottom sides because
        // the pointer coordinates are always relative to the top left
        // corner of the container, ignoring transforms.
        //
        // Once transforms are thrown into the mix, it's no longer as simple
        // as using a lookup table. Rather, the percentage needs to be
        // calculated from where the base side *appears* to be after
        // transforms are applied. If, for example, the inset is from the
        // left side but transforms make the image's left side appear as
        // the bottom side of the container, then the new inset percentage
        // needs to be calculated using the parameters for the bottom side.
        //
        // This array's order is important for the index calculations that
        // happen below. While the starting element is arbitrary, it's
        // crucial each successive element is the next apparent side if you
        // were to rotate the image once (in the positive direction). So, if
        // we have an inset from the left side and rotate the image twice,
        // the inset would appear to be the right side on screen. Add 2 (for
        // the number of rotations) to the index of the left side's params
        // and you end up at the params for the right side.
        const sideCalculationParams = [
          { axis: "x", invert: false } /* left */,
          { axis: "y", invert: false } /* top */,
          { axis: "x", invert: true } /* right */,
          { axis: "y", invert: true } /* bottom */,
        ];

        // Get the index into the params lookup table according to the base
        // inset side. This MUST have the same order as the elements in the
        // array above.
        let sideIndex = [
          ClipInsetSide.Left,
          ClipInsetSide.Top,
          ClipInsetSide.Right,
          ClipInsetSide.Bottom,
        ].indexOf(insetSide);

        if (sideCalculationParams[sideIndex].axis === flip) {
          // A flip along the same axis as the mouse coordinate that'll
          // be used is visually equivalent to 2 rotations
          sideIndex += 2;
        }

        sideIndex += calculateRotationQuadrant(rotationDeg);

        const apparentSideCalculationParams =
          sideCalculationParams[sideIndex % sideCalculationParams.length];

        const {
          payload: { pointerLocation },
        } = action;

        // TODO: Calculate relative to scaled image dimensions, not container
        let insetPercent: number;

        if (apparentSideCalculationParams.axis === "x") {
          insetPercent = pointerLocation.x / pointerLocation.width;
        } else {
          insetPercent = pointerLocation.y / pointerLocation.height;
        }

        if (apparentSideCalculationParams.invert) {
          insetPercent = 1 - insetPercent;
        }

        inferenceTopic.clipInset.percent = clamp(insetPercent, 0, 1);

        return;
      }
      case "set-point-cloud-point-size": {
        const panel = getInitializedPanel(
          draft,
          action.payload.panelId,
          VisualizationType.ThreeD,
        );

        const {
          payload: { pointSize },
        } = action;

        invariant(
          0 <= pointSize && pointSize <= 0.1,
          "Point size must be in range [0, 0.1]",
        );

        panel.topics[0].size = pointSize;

        return;
      }
      case "choose-visualization": {
        const panel = getInitializedPanel(draft, action.payload.panelId);

        changePanelVisualization(panel, action.payload.tab, overrider);

        return;
      }
      case "choose-new-topic": {
        const panel = getInitializedPanel(draft, action.payload.panelId);

        resetPanel(panel);

        return;
      }
      case "record-auto-skip": {
        const panel = getInitializedPanel(draft, action.payload.panelId);

        panel.hasAutoSkipped = true;

        return;
      }
      case "set-supplementary-map-topic": {
        const panel = getInitializedPanel(
          draft,
          action.payload.panelId,
          VisualizationType.Map,
        );

        panel.topics[1] = createMapTopic(action.payload.topic);

        return;
      }
      case "clear-supplementary-map-topic": {
        const panel = getInitializedPanel(
          draft,
          action.payload.panelId,
          VisualizationType.Map,
        );

        invariant(
          panel.topics[1] != null,
          "No supplementary map topic selected",
        );

        panel.topics = [panel.topics[0]];

        return;
      }
      default: {
        assertNever(action);
      }
    }
  };
}

// Helpers

function loadProfile(
  profile: LayoutProfileDescriptor,
): PanelLayoutReducerState {
  let maxId = -Infinity;
  for (const node of layoutTreeIterable(profile.layout)) {
    maxId = Math.max(maxId, node.id);
  }

  return {
    layout: profile.layout,
    panels: deserializePanels(profile.panels),
    nextId: maxId + 1,
    canLoadInitialProfile: false,
  };
}

function createUninitializedPanel(
  id: Panel["id"],
): WritableDraft<UninitializedPanel> {
  return {
    id,
    title: null,
    visualization: null,
    topics: [],
    topicSelectionConfig: {
      search: null,
      [VisualizationType.Image]: false,
      [VisualizationType.ThreeD]: false,
      [VisualizationType.Map]: false,
      lastSelected: null,
    },
  };
}

function resetPanel(panel: WritableDraft<InitializedPanel>): void {
  const {
    topics: [primaryTopic],
  } = panel;

  panel.title = null;
  (panel as any).visualization = null;
  (panel as any).topics = [];

  // Setting this when clearing the panel's topic rather than when
  // selecting it ensures the feature works when clearing the topic
  // for a panel loaded from a layout.
  panel.topicSelectionConfig.lastSelected = primaryTopic.name;
}

function createPrimaryTimelineTopic(
  name: Topic["name"],
): WritableDraft<TimelineTopicDescriptor> {
  return { name };
}

function createPrimaryChartTopic(
  name: Topic["name"],
): WritableDraft<ChartTopicDescriptor> {
  return { name, fields: [] };
}

function createPrimaryImageTopic(
  name: Topic["name"],
  overrider: TopicInitializationOverrider | undefined,
): WritableDraft<ImageTopicDescriptor> {
  return {
    name,
    colorize: false,
    rotationDeg: 0,
    flip: null,
    brightnessPct: 1,
    contrastPct: 1,
    ...overrider?.(VisualizationType.Image, name),
  };
}

function createPrimaryMapTopic(
  name: Topic["name"],
): WritableDraft<MapTopicDescriptor> {
  return { name };
}

function createPrimaryThreeDTopic(
  name: Topic["name"],
): WritableDraft<PointCloudTopicDescriptor> {
  return { name, size: 0.05 };
}

function changePanelVisualization(
  panel: WritableDraft<InitializedPanel>,
  newVisualization: VisualizationType,
  overrider: TopicInitializationOverrider | undefined,
): void {
  const {
    topics: [{ name: topicName }],
  } = panel;

  let newTopic;
  switch (newVisualization) {
    case VisualizationType.Timeline: {
      newTopic = createPrimaryTimelineTopic(topicName);

      break;
    }
    case VisualizationType.Chart: {
      newTopic = createPrimaryChartTopic(topicName);

      break;
    }
    case VisualizationType.Image: {
      newTopic = createPrimaryImageTopic(topicName, overrider);

      break;
    }
    case VisualizationType.Map: {
      newTopic = createPrimaryMapTopic(topicName);

      break;
    }
    case VisualizationType.ThreeD: {
      newTopic = createPrimaryThreeDTopic(topicName);

      break;
    }
    default: {
      assertNever(newVisualization);
    }
  }

  (panel as any).visualization = newVisualization;
  (panel as any).topics = [newTopic];
}

function initializePanel(
  panel: WritableDraft<UninitializedPanel>,
  topic: Topic,
  overrider: TopicInitializationOverrider | undefined,
): void {
  const defaultVisualization = getDefaultVisualization(topic.typeName);

  let newTopic;
  switch (defaultVisualization) {
    case VisualizationType.Timeline: {
      newTopic = createPrimaryTimelineTopic(topic.name);

      break;
    }
    case VisualizationType.Chart: {
      newTopic = createPrimaryChartTopic(topic.name);

      break;
    }
    case VisualizationType.Image: {
      newTopic = createPrimaryImageTopic(topic.name, overrider);

      break;
    }
    case VisualizationType.Map: {
      newTopic = createPrimaryMapTopic(topic.name);

      break;
    }
    case VisualizationType.ThreeD: {
      newTopic = createPrimaryThreeDTopic(topic.name);

      break;
    }
    default: {
      assertNever(defaultVisualization);
    }
  }

  if (panel.title === null) {
    panel.title = getTopicContextDisplayName(topic.context) ?? topic.name;
  }

  (panel as any).visualization = defaultVisualization;
  (panel as any).topics = [newTopic];
  (panel as any).hasAutoSkipped = false;
  (panel as any).selectedTag = null;
}

function getLayoutNode(
  draft: Draft<PanelLayoutReducerState>,
  nodeId: LayoutNode["id"],
): WritableDraft<LayoutNode> {
  for (const node of layoutTreeIterable(draft.layout)) {
    if (node.id === nodeId) {
      return node as WritableDraft<LayoutNode>;
    }
  }

  throw new Error(`No node found with ID ${nodeId}`);
}

function getParentContainerNode(
  draft: Draft<PanelLayoutReducerState>,
  childId: LayoutNode["id"],
): WritableDraft<LayoutContainerNode> | null {
  for (const node of layoutTreeIterable(draft.layout)) {
    if (
      "children" in node &&
      (node.children[0].id === childId || node.children[1].id === childId)
    ) {
      return node as WritableDraft<LayoutContainerNode>;
    }
  }

  return null;
}

function getLayoutPanelNode(
  draft: Draft<PanelLayoutReducerState>,
  nodeId: LayoutPanelNode["id"],
): WritableDraft<LayoutPanelNode> {
  const node = getLayoutNode(draft, nodeId);

  invariant("panelId" in node, `Node with ID ${nodeId} is not a panel`);

  return node;
}

function getPanel(
  draft: Draft<PanelLayoutReducerState>,
  panelId: Panel["id"],
): WritableDraft<Panel> {
  const panel = draft.panels.find((panel) => panel.id === panelId);

  invariant(panel != null, `No panel found with ID ${panelId}`);

  return panel;
}

function getUninitializedPanel(
  draft: Draft<PanelLayoutReducerState>,
  panelId: Panel["id"],
): WritableDraft<UninitializedPanel> {
  const panel = getPanel(draft, panelId);

  invariant(
    !checkIsPanelInitialized(panel),
    `Panel with ID ${panelId} is already initialized`,
  );

  return panel;
}

function getInitializedPanel<
  TType extends VisualizationType | undefined = undefined,
>(
  draft: Draft<PanelLayoutReducerState>,
  panelId: Panel["id"],
  visualizationType?: TType,
): WritableDraft<
  TType extends undefined
    ? InitializedPanel
    : Extract<InitializedPanel, { visualization: TType }>
> {
  const panel = getPanel(draft, panelId);

  invariant(
    checkIsPanelInitialized(panel),
    `Panel with ID ${panelId} is not initialized`,
  );

  invariant(
    visualizationType == null || panel.visualization === visualizationType,
    `Panel is not of expected type: ${visualizationType}`,
  );

  return panel as any;
}

function getBaseImageTopic(
  panel: ImagePanel,
): WritableDraft<ImageTopicDescriptor> {
  return panel.topics[0];
}

function getInferenceTopic(
  panel: WritableDraft<ImagePanelWithInference>,
): WritableDraft<InferenceTopicDescriptor> {
  return panel.topics[1];
}

function hasInferenceTopic(
  panel: WritableDraft<ImagePanel>,
): panel is WritableDraft<ImagePanelWithInference> {
  return panel.topics[1] != null;
}

function requireInferenceTopic(
  panel: WritableDraft<ImagePanel>,
): WritableDraft<InferenceTopicDescriptor> {
  invariant(hasInferenceTopic(panel), "Panel has no inference topic");

  return getInferenceTopic(panel);
}

function createInferenceTopic(
  topic: Topic,
): WritableDraft<InferenceTopicDescriptor> {
  return {
    name: topic.name,
    colorize: false,
    rotationDeg: 0,
    flip: null,
    lockTransforms: true,
    showDetectionBoundingBoxes: true,
    showDetectionClassNames: true,
    hiddenObjectClassNames: [],
    opacity: 1,
    clipInset: {
      side: ClipInsetSide.Left,
      percent: 0.5,
    },
  };
}

function createMapTopic(topic: Topic): WritableDraft<MapTopicDescriptor> {
  return {
    name: topic.name,
  };
}

function calculateNewRotationDeg(
  currentRotationDeg: number,
  direction: RotationDirection,
): number {
  const rotateByDeg =
    direction === RotationDirection.Left
      ? -IMAGE_ROTATION_STEP_MAGNITUDE_DEG
      : IMAGE_ROTATION_STEP_MAGNITUDE_DEG;

  return currentRotationDeg + rotateByDeg;
}
