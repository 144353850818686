import type { UseQueryResult } from "@tanstack/react-query";
import type { Log, LogDataResponse } from "~/lqs";
import { useLog } from "~/lqs";
import { selectData } from "~/utils";
import { usePlayerParams } from "./use-player-params";

export function usePlayerLog(): UseQueryResult<Log>;
export function usePlayerLog<TData>(
  select: (response: LogDataResponse) => TData,
): UseQueryResult<TData>;
export function usePlayerLog<TData = Log>(
  select: (response: LogDataResponse) => TData = selectData as any,
): UseQueryResult<TData | Log> {
  const { logId } = usePlayerParams();

  return useLog(logId, { select });
}
