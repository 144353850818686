import React, { useState } from "react";
import type { QueryClient } from "@tanstack/react-query";
import { QueryClientProvider } from "@tanstack/react-query";
import { createSafeContext } from "~/contexts";
import { createQueryClient } from "~/create-query-client";
import type { Configuration } from "./sdk";
import {
  DataStoreApi,
  DataStoreAssociationApi,
  RoleApi,
  TicketApi,
  UserApi,
} from "./sdk";
import type { DsmClients } from "./types";
import { DsmVersionProvider } from "./versioning";

export const [useDsmContext, DsmContext] = createSafeContext<DsmClients>("DSM");

export const DsmQueryClientContext = React.createContext<
  QueryClient | undefined
>(undefined);

export function DsmQueryClientProvider({
  client,
  children,
}: {
  client: QueryClient;
  children: React.ReactNode;
}) {
  return (
    <QueryClientProvider
      client={client}
      // Isolate DSM queries and mutations while still being accessible
      // anywhere in the tree if this context is passed to the TanStack hooks.
      context={DsmQueryClientContext}
    >
      {children}
    </QueryClientProvider>
  );
}

export function DsmProvider({
  createApiConfiguration,
  children,
}: {
  /**
   * Create a configuration object to pass to the SDK clients. Will only be
   * called once when the provider is first rendered.
   */
  createApiConfiguration: () => Configuration;
  children: React.ReactNode;
}) {
  const [clients] = useState((): DsmClients => {
    const configuration = createApiConfiguration();

    return {
      dataStoreApi: new DataStoreApi(configuration),
      dataStoreAssociationApi: new DataStoreAssociationApi(configuration),
      roleApi: new RoleApi(configuration),
      ticketApi: new TicketApi(configuration),
      userApi: new UserApi(configuration),
    };
  });

  const [queryClient] = useState(createQueryClient);

  return (
    <DsmContext.Provider value={clients}>
      <DsmQueryClientProvider client={queryClient}>
        <DsmVersionProvider>{children}</DsmVersionProvider>
      </DsmQueryClientProvider>
    </DsmContext.Provider>
  );
}
