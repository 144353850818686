import type { StrictExtract } from "ts-essentials";
import { invariant } from "~/lib/invariant";
import type { Topic } from "~/lqs";
import type { Maybe } from "~/types";
import { assertNever } from "~/utils";
import type { DataFilter } from "../types";
import { VisualizationType } from "./constants";
import type { Panel } from "./types";
import {
  checkIsPanelInitialized,
  findTopicForDescriptor,
  getPrimaryTopicDescriptor,
} from "./utils";

export type VisualizationFilter = StrictExtract<
  VisualizationType,
  "image" | "three-d" | "map"
>;

const IMAGE_TOPICS = [
  "sensor_msgs/Image",
  "sensor_msgs/CompressedImage",
  "stereo_msgs/DisparityImage",
  "sensor_msgs/msg/Image",
  "sensor_msgs/msg/CompressedImage",
  "stereo_msgs/msg/DisparityImage",
  "logqs.inference.depth-estimation",
  "logqs.inference.image-segmentation",
  "logqs.inference.image-to-image",
  "ark::image::Image",
  "logqs.image",
  "lqs_cutter/navcon_image",
];

const THREE_D_TOPICS = [
  "sensor_msgs/PointCloud2",
  "sensor_msgs/msg/PointCloud2",
];

export interface GeographicPointConfiguration {
  longitude: string;
  latitude: string;
}

const GEOGRAPHIC_MAP_TOPICS = new Map<
  NonNullable<Topic["typeName"]>,
  GeographicPointConfiguration
>([
  [
    "sensor_msgs/NavSatFix",
    {
      longitude: "longitude",
      latitude: "latitude",
    },
  ],
  [
    "sensor_msgs/msg/NavSatFix",
    {
      longitude: "longitude",
      latitude: "latitude",
    },
  ],
  [
    "ark::gps::GpsFix",
    {
      longitude: "longitude",
      latitude: "latitude",
    },
  ],
  [
    "lqs_cutter/navcon_gps",
    {
      longitude: "lon",
      latitude: "lat",
    },
  ],
]);

export interface CartesianPointConfiguration {
  x: string;
  y: string;
  queryDataFilter?: DataFilter | ReadonlyArray<DataFilter>;
}

const CARTESIAN_MAP_TOPICS = new Map<
  NonNullable<Topic["typeName"]>,
  CartesianPointConfiguration
>([
  [
    "lqs_cutter/navcon_pose_filter",
    {
      x: "position.x",
      y: "position.z",
    },
  ],
  [
    "lqs_cutter/navcon_called_shot",
    {
      x: "pose.position.x",
      y: "pose.position.z",
      // The goal here is to filter for records where `pose.position` actually
      // exists since not every record of this type has a `pose` field. Until
      // there's a data filter operator to check for existence, we'll just
      // "compare" against the largest possible float value (which should match
      // basically every record with `pose.position`) because any record
      // _without_ `pose.position` will be removed.
      queryDataFilter: [
        {
          var: "pose.position.x",
          op: "<=",
          val: Number.MAX_VALUE,
        },
        {
          var: "pose.position.z",
          op: "<=",
          val: Number.MAX_VALUE,
        },
      ],
    },
  ],
]);

export function getSupportedVisualizations(
  panel: Panel,
  topics: ReadonlyArray<Topic> | undefined,
): Record<VisualizationType, boolean> {
  let typeName: Maybe<Topic["typeName"]> = null;
  if (checkIsPanelInitialized(panel)) {
    typeName = findTopicForDescriptor(
      getPrimaryTopicDescriptor(panel),
      topics,
    )?.typeName;
  }

  const defaultVisualization =
    typeName != null && getDefaultVisualization(typeName);

  return {
    [VisualizationType.Image]: defaultVisualization === VisualizationType.Image,
    [VisualizationType.ThreeD]:
      defaultVisualization === VisualizationType.ThreeD,
    [VisualizationType.Map]: defaultVisualization === VisualizationType.Map,
    [VisualizationType.Chart]: true,
    [VisualizationType.Timeline]: true,
  };
}

export function getDefaultVisualization(
  typeName: Topic["typeName"],
): VisualizationType {
  switch (true) {
    case supportsVisualization(typeName, VisualizationType.Image): {
      return VisualizationType.Image;
    }
    case supportsVisualization(typeName, VisualizationType.ThreeD): {
      return VisualizationType.ThreeD;
    }
    case supportsVisualization(typeName, VisualizationType.Map): {
      return VisualizationType.Map;
    }
    default: {
      return VisualizationType.Timeline;
    }
  }
}

export function supportsVisualization(
  typeName: Topic["typeName"],
  visualization: VisualizationFilter,
): boolean {
  switch (visualization) {
    case VisualizationType.Image: {
      return IMAGE_TOPICS.includes(typeName!);
    }
    case VisualizationType.ThreeD: {
      return THREE_D_TOPICS.includes(typeName!);
    }
    case VisualizationType.Map: {
      return (
        GEOGRAPHIC_MAP_TOPICS.has(typeName!) ||
        CARTESIAN_MAP_TOPICS.has(typeName!)
      );
    }
    default: {
      assertNever(visualization);
    }
  }
}

export function getMapSubVisualization(
  typeName: Topic["typeName"],
): "gps" | "cartesian" {
  switch (true) {
    case GEOGRAPHIC_MAP_TOPICS.has(typeName!): {
      return "gps";
    }
    case CARTESIAN_MAP_TOPICS.has(typeName!): {
      return "cartesian";
    }
    default: {
      throw new Error(
        `Topic with type "${typeName}" does not support map visualization`,
      );
    }
  }
}

export function getGeographicPointConfiguration(
  typeName: Topic["typeName"],
): GeographicPointConfiguration {
  const config = GEOGRAPHIC_MAP_TOPICS.get(typeName!);

  invariant(
    config != null,
    `No geographic point configuration found for type '${typeName}'`,
  );

  return config;
}

export function getCartesianPointConfiguration(
  typeName: Topic["typeName"],
): CartesianPointConfiguration {
  const config = CARTESIAN_MAP_TOPICS.get(typeName!);

  invariant(
    config != null,
    `No cartesian point configuration found for type '${typeName}'`,
  );

  return config;
}
