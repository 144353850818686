import React from "react";
import { Typography } from "@mui/material";
import { invariant } from "~/lib/invariant";
import type { Topic } from "~/lqs";
import { usePlayerActions } from "../actions";
import { TopicSelect } from "../components";
import { useLoadedPlayerTopics } from "../hooks";
import {
  checkIsImagePanelWithInference,
  useInitializedPanel,
  usePanelTopic,
  VisualizationType,
} from "../panels";
import { useImageFrames } from "../visualizations";
import {
  DetectionClassificationControls,
  DetectionResultsControls,
} from "./detections";
import { ImageResultsControls } from "./images";
import { isInferenceTopic } from "./inference-config";
import { ImageSegmentationResultsControls } from "./segmentations";
import { searchForInferenceTopic } from "./utils";

export function InferenceFrameControls() {
  const panel = useInitializedPanel();

  invariant(
    panel.visualization === VisualizationType.Image,
    "Panel is not of type image",
  );

  const topic = usePanelTopic();

  const playerActions = usePlayerActions();

  const playerTopics = useLoadedPlayerTopics();

  const inferenceTopicSearchResult = searchForInferenceTopic(
    panel,
    playerTopics,
  );

  const imageFrames = useImageFrames({
    topic,
    inferenceTopic: inferenceTopicSearchResult.topic,
  });
  const imageFrame = imageFrames.snapshot.value?.current;

  const inferenceTopics = filterInferenceTopics(topic, playerTopics);

  const handleInferenceTopicChange = (newValue: Topic | null) => {
    playerActions.setInferenceTopic(panel, newValue);
  };

  let typeSpecificControls = null;
  if (imageFrame != null && checkIsImagePanelWithInference(panel)) {
    const { inferenceFrames } = imageFrame;

    switch (inferenceFrames?.type) {
      case "object-detection":
      case "zero-shot-object-detection": {
        typeSpecificControls = (
          <DetectionResultsControls
            panel={panel}
            results={inferenceFrames.current}
            classificationControls={
              <DetectionClassificationControls
                topic={topic}
                timestamp={imageFrame.timestamp}
                inferenceFrames={inferenceFrames}
              />
            }
          />
        );
        break;
      }
      case "image-segmentation": {
        typeSpecificControls = (
          <ImageSegmentationResultsControls
            panel={panel}
            results={inferenceFrames.current}
          />
        );
        break;
      }
      case "depth-estimation": {
        typeSpecificControls = <ImageResultsControls panel={panel} />;
        break;
      }
    }
  }

  return (
    <>
      <Typography variant="h6" component="p" sx={{ mb: 2 }}>
        Inference Tasks
      </Typography>
      <TopicSelect
        sx={{ mb: 1 }}
        inputLabel="Inference Topic"
        value={inferenceTopicSearchResult.topic ?? null}
        topics={inferenceTopics}
        onChange={handleInferenceTopicChange}
      />
      {inferenceTopicSearchResult.status === "missing" && (
        <Typography variant="subtitle2" component="p">
          {inferenceTopicSearchResult.name} is not in this log
        </Typography>
      )}
      {typeSpecificControls}
    </>
  );
}

function filterInferenceTopics(
  baseTopic: Topic,
  playerTopics: ReadonlyArray<Topic>,
): Array<Topic> {
  return playerTopics.filter(
    (topic) =>
      topic.associatedTopicId === baseTopic.id && isInferenceTopic(topic),
  );
}
