import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Loading } from "~/components/Loading";
import { ErrorMessage } from "~/components/error-message";
import type { Topic } from "~/lqs";
import { assertNever } from "~/utils/assertNever";
import { PanelLayout } from "../../components";
import type { MapPanel } from "../../panels";
import { getMapSubVisualization } from "../../panels";

const LazyCartesianVisualization = React.lazy(
  () => import("./cartesian-visualization"),
);
const LazyGpsVisualization = React.lazy(() => import("./gps-visualization"));

export function MapVisualization({
  panel,
  topic,
}: {
  panel: MapPanel;
  topic: Topic;
}) {
  const subVisualization = getMapSubVisualization(topic.typeName);
  switch (subVisualization) {
    case "gps": {
      return renderLazyVisualization(
        <LazyGpsVisualization panel={panel} topic={topic} />,
      );
    }
    case "cartesian": {
      return renderLazyVisualization(
        <LazyCartesianVisualization panel={panel} topic={topic} />,
      );
    }
    default: {
      assertNever(subVisualization);
    }
  }
}

function renderLazyVisualization(visualization: React.JSX.Element) {
  return (
    <ErrorBoundary
      fallback={renderPanelFallback(
        <ErrorMessage>Could not initialize map visualization</ErrorMessage>,
      )}
    >
      <React.Suspense
        fallback={renderPanelFallback(<Loading type="circular" />)}
      >
        {visualization}
      </React.Suspense>
    </ErrorBoundary>
  );
}

function renderPanelFallback(children: React.ReactNode) {
  return <PanelLayout>{children}</PanelLayout>;
}
