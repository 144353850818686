import React, { useMemo, useState } from "react";
import ChevronRight from "@mui/icons-material/ChevronRight";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Button, ButtonGroup, Stack, Typography } from "@mui/material";
import { TreeItem, TreeView } from "@mui/x-tree-view";
import type { Maybe } from "~/types";
import { pluralize } from "~/utils";

export function JsonTree({
  src,
  onSelect,
  itemDisabled = false,
}: {
  src: Maybe<object>;
  onSelect?: (path: string) => void;
  itemDisabled?:
    | boolean
    | ((key: string, value: unknown, path: string) => boolean);
}) {
  const nodeIds = useMemo(() => getNodeIds(src), [src]);

  const [expanded, setExpanded] = useState(nodeIds);

  function handleToggle(_: unknown, newNodeIds: Array<string>): void {
    setExpanded(newNodeIds);
  }

  function handleSelect(_: unknown, nodeId: string): void {
    onSelect?.(nodeId);
  }

  function handleExpandAll(): void {
    setExpanded(nodeIds);
  }

  function handleCollapseAll(): void {
    setExpanded([]);
  }

  if (src == null) {
    return null;
  }

  return (
    <Stack spacing={1}>
      <ButtonGroup variant="outlined" size="small">
        <Button onClick={handleExpandAll}>Expand All</Button>
        <Button onClick={handleCollapseAll}>Collapse All</Button>
      </ButtonGroup>
      <TreeView
        defaultCollapseIcon={<ExpandMore />}
        defaultExpandIcon={<ChevronRight />}
        expanded={expanded}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
      >
        {Object.entries(src).map((entry) => (
          <Entry key={entry[0]} entry={entry} itemDisabled={itemDisabled} />
        ))}
      </TreeView>
    </Stack>
  );
}

function Entry({
  entry: [key, value],
  itemDisabled,
  parentNodeId,
}: {
  entry: [string, unknown];
  itemDisabled:
    | boolean
    | ((key: string, value: unknown, path: string) => boolean);
  parentNodeId?: string;
}) {
  const nodeId = parentNodeId == null ? key : `${parentNodeId}.${key}`;

  let label;
  let children;
  let disableTreeItem = false;
  if (checkIsContainer(value)) {
    const entries = Object.entries(value);

    label = (
      <>
        <Typography component="span">
          {Array.isArray(value) ? "[ ... ]" : "{ ... }"}
        </Typography>{" "}
        <Typography
          component="span"
          variant="body2"
          sx={{ color: "text.secondary" }}
        >
          {pluralize(
            entries.length,
            Array.isArray(value) ? "element" : "field",
          )}
        </Typography>
      </>
    );

    children = entries.map((entry) => (
      <Entry
        key={entry[0]}
        entry={entry}
        itemDisabled={itemDisabled}
        parentNodeId={nodeId}
      />
    ));
  } else {
    label = (
      <>
        <Typography component="span">{JSON.stringify(value)}</Typography>{" "}
        <Typography
          component="span"
          variant="body2"
          sx={{ color: "text.secondary" }}
        >
          {typeof value}
        </Typography>
      </>
    );

    children = null;

    disableTreeItem =
      typeof itemDisabled === "boolean"
        ? itemDisabled
        : itemDisabled(key, value, nodeId);
  }

  return (
    <TreeItem
      nodeId={nodeId}
      label={
        <>
          <Typography component="span" sx={{ fontWeight: "bold" }}>
            {Number.isFinite(Number(key)) ? key : JSON.stringify(key)}
          </Typography>
          {": "}
          {label}
        </>
      }
      disabled={disableTreeItem}
    >
      {children}
    </TreeItem>
  );
}

function getNodeIds(src: Maybe<object>): Array<string> {
  if (src == null) {
    return [];
  }

  const nodeIds = new Array<string>();

  const sources: Array<{ value: object; path: string | null }> = [
    { value: src, path: null },
  ];
  while (sources.length > 0) {
    const currentSrc = sources.shift()!;

    for (const [key, value] of Object.entries(currentSrc.value) as Array<
      [string, unknown]
    >) {
      const path = currentSrc.path === null ? key : `${currentSrc.path}.${key}`;
      nodeIds.push(path);

      if (checkIsContainer(value)) {
        sources.push({ value, path });
      }
    }
  }

  return nodeIds;
}

function checkIsContainer(value: unknown): value is object {
  return (
    typeof value === "object" && value !== null && !(value instanceof Date)
  );
}
