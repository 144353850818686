import { commonResourceFormFieldDescriptors } from "~/domain/common";
import { LqsFiltersSidebar } from "~/lqs";
import { listObjectStoresSchema } from "../schemas";
import { useObjectStoreSearchRequest } from "./ObjectStoreTable";

export function ObjectStoreFilters() {
  const [request, setRequest] = useObjectStoreSearchRequest();

  return (
    <LqsFiltersSidebar
      registryKey="objectStore"
      request={request}
      setRequest={setRequest}
      schema={listObjectStoresSchema}
      descriptors={[
        { name: "id", type: "text" },
        { name: "bucketName", type: "fuzzy", fuzzyName: "bucketNameLike" },
        { name: "name", type: "fuzzy", fuzzyName: "nameLike" },
        { name: "accessKeyId", type: "text" },
        { name: "regionName", type: "text" },
        { name: "endpointUrl", type: "text" },
        { name: "disabled", type: "boolean" },
        { name: "note", type: "fuzzy", fuzzyName: "noteLike" },
        ...commonResourceFormFieldDescriptors,
      ]}
    />
  );
}
