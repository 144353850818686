import type { Topic } from "~/lqs";
import type { Maybe } from "~/types";
import type { ImagePanel } from "../panels";
import {
  checkIsImagePanelWithInference,
  getInferenceTopicDescriptor,
  findTopicForDescriptor,
} from "../panels";

export type InferenceTopicSearchResult =
  | { status: "found"; name?: undefined; topic: Topic }
  | { status: "unset"; name?: undefined; topic?: undefined }
  | { status: "missing"; name: Topic["name"]; topic?: undefined };

export function searchForInferenceTopic(
  panel: Maybe<ImagePanel>,
  playerTopics: Maybe<ReadonlyArray<Topic>>,
): InferenceTopicSearchResult {
  if (panel == null || playerTopics == null) {
    // This case currently only happens when the on-demand inference provider
    // searches for a possibly-nullish panel's inference topic and/or the
    // player topics query hasn't fulfilled yet. In either situation, it
    // shouldn't do anything with the result so this value should be fine as
    // opposed to adding another type to the union which other callers would
    // then have to handle.
    // TODO: Is this necessary now that ODI is removed?
    return { status: "unset" };
  }

  if (!checkIsImagePanelWithInference(panel)) {
    return { status: "unset" };
  }

  const descriptor = getInferenceTopicDescriptor(panel);

  const topic = findTopicForDescriptor(descriptor, playerTopics);

  if (topic == null) {
    return { status: "missing", name: descriptor.name };
  } else {
    return { status: "found", topic };
  }
}
