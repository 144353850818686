import { Button, Stack, Typography } from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import { Link as RouterLink } from "react-router-dom";
import { Card } from "~/components/Card";
import {
  DetailsLayout,
  LockCard,
  RelatedResource,
} from "~/components/DetailsCards";
import type { Log } from "~/lqs";
import {
  EditLqsResourceForm,
  LqsHistoryCard,
  LqsManageCard,
  LqsResourceFields,
  useDeleteLog,
  useLog,
  useUpdateLog,
} from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { editLogSchema } from "../schemas";
import {
  LogObjectSearchRequestProvider,
  LogObjectTable,
} from "./LogObjectTable";
import { LogQuerySearchRequestProvider, LogQueryTable } from "./LogQueryTable";
import { TagSearchRequestProvider, TagTable } from "./TagTable";

export function LogDetails() {
  const { logId } = useTypedParams(lqsRoutePaths.LOG_DETAILS);

  const lqsNavigator = useLqsNavigator({
    toLogTagTable: true,
    toLogObjectTable: true,
    toLogQueryTable: true,
    toLogEdit: true,
    toLogTable: true,
  });

  const query = useLog(logId, { select: selectData });

  const generalSection = <GeneralSection query={query} />;
  const relatedResourcesSection = (
    <Stack spacing={2}>
      <Typography variant="h4" component="h2">
        Related Resources
      </Typography>
      <RelatedResource
        text="Tags"
        to={lqsNavigator.toLogTagTable({ logId })}
        table={
          <TagSearchRequestProvider embedded>
            <TagTable />
          </TagSearchRequestProvider>
        }
      />
      <RelatedResource
        text="Objects"
        to={lqsNavigator.toLogObjectTable({ logId })}
        table={
          <LogObjectSearchRequestProvider embedded>
            <LogObjectTable logId={logId} />
          </LogObjectSearchRequestProvider>
        }
      />
      <RelatedResource
        text="Queries"
        to={lqsNavigator.toLogQueryTable({ logId })}
        table={
          <LogQuerySearchRequestProvider embedded>
            <LogQueryTable />
          </LogQuerySearchRequestProvider>
        }
      />
    </Stack>
  );
  const infoSection = <InfoSection query={query} />;
  const lockSection = (
    <LockCard
      resourceName="log"
      query={query}
      updateMutation={useUpdateLog(logId)}
    />
  );
  const historySection = <LqsHistoryCard query={query} />;
  const manageSection = (
    <LqsManageCard
      resourceName="log"
      query={query}
      editLocation={lqsNavigator.toLogEdit({ logId })}
      deleteMutation={useDeleteLog(logId)}
      getReadableName={(log) => log.name}
      listLocation={lqsNavigator.toLogTable()}
    />
  );

  return (
    <DetailsLayout
      primaryGridColumn={
        <>
          {generalSection}
          {relatedResourcesSection}
        </>
      }
      secondaryGridColumn={
        <>
          {infoSection}
          {lockSection}
          {historySection}
          {manageSection}
        </>
      }
      stack={
        <>
          {generalSection}
          {infoSection}
          {lockSection}
          {historySection}
          {manageSection}
          {relatedResourcesSection}
        </>
      }
    />
  );
}

function GeneralSection({ query }: { query: UseQueryResult<Log> }) {
  const { logId } = useTypedParams(lqsRoutePaths.LOG_DETAILS);

  const lqsNavigator = useLqsNavigator();

  return (
    <Card
      title="General"
      action={
        <Button
          variant="contained"
          component={RouterLink}
          to={lqsNavigator.toPlayer({ logId })}
        >
          Open in Player
        </Button>
      }
    >
      <LqsResourceFields
        registryKey="log"
        query={query}
        fields={[
          { dataType: "id", accessor: "id" },
          {
            dataType: "foreign-key",
            resourceType: "group",
            accessor: "groupId",
          },
          {
            dataType: "foreign-key",
            resourceType: "workflow",
            accessor: "defaultWorkflowId",
          },
          { dataType: "timestamp", accessor: "startTime" },
          { dataType: "timestamp", accessor: "endTime" },
          { dataType: "duration", accessor: "duration" },
          { dataType: "timestamp", accessor: "baseTimestamp" },
          { dataType: "bytes", accessor: "recordSize" },
          { dataType: "number", accessor: "recordCount" },
          { dataType: "bytes", accessor: "objectSize" },
          { dataType: "number", accessor: "objectCount" },
          { dataType: "json", accessor: "context" },
        ]}
      />
    </Card>
  );
}

function InfoSection({ query }: { query: UseQueryResult<Log> }) {
  const { logId } = useTypedParams(lqsRoutePaths.LOG_DETAILS);

  return (
    <EditLqsResourceForm
      resourceName="log"
      registryKey="log"
      disabled={(log) => log.locked}
      footer={(log) =>
        log.locked && (
          <Typography variant="body2">
            Unlock this log to make updates.
          </Typography>
        )
      }
      query={query}
      schema={editLogSchema}
      descriptors={[
        { name: "name", type: "text", required: true },
        { name: "note", type: "text", multiline: true },
      ]}
      mutation={useUpdateLog(logId)}
    />
  );
}
