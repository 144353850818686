import type { FormEventHandler } from "react";
import { useState } from "react";
import { Error } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { renderQuery } from "~/components/QueryRenderer";
import { invariant } from "~/lib/invariant";
import type { LayoutProfileDescriptor } from "../panels";
import {
  serializePanels,
  useCreateLayoutProfile,
  useDeleteLayoutProfile,
  usePanelLayoutContext,
  useSetDefaultLogLayoutProfile,
  useUserLayoutProfiles,
} from "../panels";

export function ProfileDialog({
  open,
  setOpen,
  disableSettingDefaultLayout,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  disableSettingDefaultLayout: boolean;
}) {
  const [name, setName] = useState("");
  const [error, setError] = useState("");

  const [selected, setSelected] = useState<
    LayoutProfileDescriptor["name"] | null
  >(null);

  const { layout, panels } = usePanelLayoutContext();

  const userLayoutProfilesQuery = useUserLayoutProfiles();
  const createLayoutProfile = useCreateLayoutProfile();
  const deleteLayoutProfile = useDeleteLayoutProfile();
  const setDefaultLogLayoutProfile = useSetDefaultLogLayoutProfile();

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit: FormEventHandler = function handleSubmit(e) {
    e.preventDefault();

    invariant(
      userLayoutProfilesQuery.isSuccess,
      "Cannot create profile before others have loaded",
    );

    if (name === "") {
      setError("Name is required");

      return;
    }

    if (userLayoutProfilesQuery.data.some((profile) => profile.name === name)) {
      setError("A profile with this name already exists");

      return;
    }

    createLayoutProfile.mutate(
      { name, layout, panels: serializePanels(panels) },
      {
        onSuccess() {
          enqueueSnackbar("Layout profile created", {
            variant: "success",
          });
        },
        onError() {
          enqueueSnackbar("Unable to create layout profile", {
            variant: "error",
          });
        },
      },
    );
  };

  function handleSetDefaultLayoutProfile(): void {
    invariant(selected !== null, "No profile selected");
    invariant(
      userLayoutProfilesQuery.isSuccess,
      "Haven't fetched layout profiles",
    );

    const profile = userLayoutProfilesQuery.data.find(
      (profile) => profile.name === selected,
    );
    invariant(profile != null, `No profile found with name "${selected}"`);

    setDefaultLogLayoutProfile.mutate(
      { profile },
      {
        onSuccess() {
          enqueueSnackbar(`Profile "${selected}" set as log's default`, {
            variant: "success",
          });
        },
        onError() {
          enqueueSnackbar(
            `Unable to set profile "${selected}" as log's default`,
            { variant: "error" },
          );
        },
      },
    );
  }

  function handleDelete(): void {
    invariant(selected !== null, "Must select a profile to delete");

    deleteLayoutProfile.mutate(selected, {
      onSuccess() {
        setSelected(null);

        enqueueSnackbar(`Deleted profile "${selected}"`, {
          variant: "success",
        });
      },
      onError() {
        enqueueSnackbar("Unable to delete profile", {
          variant: "error",
        });
      },
    });
  }

  return (
    <Dialog
      aria-labelledby="profile-dialog-title"
      fullWidth
      open={open}
      onClose={() => {
        // Don't let user close dialog while request in progress
        if (!(createLayoutProfile.isLoading || deleteLayoutProfile.isLoading)) {
          setOpen(false);
        }
      }}
      TransitionProps={{
        onExited() {
          setName("");
          setError("");
          createLayoutProfile.reset();

          setSelected(null);
          deleteLayoutProfile.reset();
        },
      }}
    >
      <DialogTitle id="profile-dialog-title">
        Manage Your Layout Profiles
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h6" component="p" gutterBottom>
          Create a New Profile
        </Typography>
        <Typography paragraph>
          Save your current layout in a profile. You'll be able to quickly load
          the layout later for any log.
        </Typography>
        <Stack
          component="form"
          onSubmit={handleSubmit}
          spacing={2}
          sx={{ alignItems: "baseline" }}
        >
          <TextField
            fullWidth
            label="Profile name"
            helperText={error}
            error={Boolean(error)}
            disabled={createLayoutProfile.isLoading}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            disableElevation
            loading={createLayoutProfile.isLoading}
            disabled={name === ""}
          >
            Create Profile
          </LoadingButton>
        </Stack>
        <Divider sx={{ my: 2 }} />
        <Typography variant="h6" component="p" gutterBottom>
          Your Profiles
        </Typography>
        <List>
          {renderQuery(userLayoutProfilesQuery, {
            loading: (
              <ListItem>
                <ListItemText>Fetching your profiles...</ListItemText>
              </ListItem>
            ),
            error: (
              <ListItem>
                <ListItemIcon>
                  <Error color="error" />
                </ListItemIcon>
                <ListItemText>Unable to get your profiles</ListItemText>
              </ListItem>
            ),
            success(data) {
              if (data.length === 0) {
                return (
                  <ListItem>
                    <ListItemText>No profiles</ListItemText>
                  </ListItem>
                );
              } else {
                return data.map((profile) => (
                  <ListItem key={profile.name} disablePadding>
                    <ListItemButton
                      role={undefined}
                      disabled={deleteLayoutProfile.isLoading}
                      selected={profile.name === selected}
                      onClick={() => setSelected(profile.name)}
                    >
                      <ListItemText>{profile.name}</ListItemText>
                    </ListItemButton>
                  </ListItem>
                ));
              }
            },
          })}
        </List>
        {userLayoutProfilesQuery.isSuccess && (
          <>
            {selected === null ? (
              <Typography paragraph sx={{ fontStyle: "italic" }}>
                No profile selected
              </Typography>
            ) : (
              <Typography sx={{ "& span": { fontWeight: "bold" } }} paragraph>
                Selected profile: <span>{selected}</span>
              </Typography>
            )}
            <Stack direction="row" spacing={2}>
              {!disableSettingDefaultLayout && (
                <LoadingButton
                  color="primary"
                  variant="contained"
                  disableElevation
                  disabled={
                    selected === null || !userLayoutProfilesQuery.isSuccess
                  }
                  loading={setDefaultLogLayoutProfile.isLoading}
                  onClick={handleSetDefaultLayoutProfile}
                >
                  Set as Log's Default
                </LoadingButton>
              )}

              <LoadingButton
                color="primary"
                variant="text"
                disableElevation
                disabled={selected === null}
                loading={deleteLayoutProfile.isLoading}
                onClick={handleDelete}
              >
                Delete Profile
              </LoadingButton>
            </Stack>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
