import * as React from "react";

// If you need to conditionally render multiple children under MUI's `<Menu />`
// then they require you to pass an array rather than a fragment. Passing an
// array is inconvenient because you then need to add keys to everything and
// ensure they're unique keys, even though the children are statically known
// and shouldn't really need a key. For multiple *static* React nodes that need
// to be conditionally rendered as menu children, this function provides a
// slightly more ergonomic way to render them as an array without manually
// managing keys. For any child that can hold a key, this function clones the
// element and attaches a key equal to its index in the array, hence why the
// children *must* be static.
export function staticConditionalMenuChildren(
  ...children: ReadonlyArray<React.ReactNode>
): Array<React.ReactNode> {
  return children.map((child, index) =>
    React.isValidElement(child)
      ? React.cloneElement(child, { key: index })
      : child,
  );
}
