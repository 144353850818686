import * as z from "zod";
import { getFieldLabel } from "~/domain/common";
import type {
  ApiVersion,
  DeprecationPolicy,
  VersionHistories,
  VersionHistory,
} from "~/domain/versioning";
import { useLocalStorage } from "~/hooks";
import { makeCellRenderer } from "./cells";
import type {
  AccessorColumn,
  Column,
  ForeignResourceRenderer,
  NormalizedColumn,
} from "./types";

const VISIBLE_COLUMNS_STORAGE_KEY_PREFIX = "column-visibility";

// Previously the visible columns were stored as a list of the visible column
// names per resource. This prevented new columns which should be visible by
// default from being made visible as their names wouldn't be in that list. The
// lookup object format is the newer way column visibility is tracked where
// once a column's visibility is toggled by the user that value will be stored
// in a lookup object. If no entry is found for a given column, its default
// visibility is used until the user manually toggles it.
const legacyVisibleColumnsSchema = z.array(z.string());
const recordVisibleColumnsSchema = z.record(z.string(), z.boolean());
const visibleColumnsSchema = z
  .union([legacyVisibleColumnsSchema, recordVisibleColumnsSchema])
  .transform((value) => {
    if (!Array.isArray(value)) {
      return value;
    }

    return Object.fromEntries(value.map((columnName) => [columnName, true]));
  });

export function useColumnVisibility<TResource extends object>(
  key: string,
  columns: ReadonlyArray<NormalizedColumn<TResource>>,
) {
  const [visibleColumnsHeaders, setVisibleColumnsHeaders] = useLocalStorage(
    `${VISIBLE_COLUMNS_STORAGE_KEY_PREFIX}.${key}`,
    Object.fromEntries(
      columns.map((column) => [column.header, !column.defaultHidden]),
    ),
    (value) => visibleColumnsSchema.parse(value),
  );

  return {
    visibleColumns: columns.filter(
      (column) => visibleColumnsHeaders[column.header] ?? !column.defaultHidden,
    ),
    toggleColumnVisibility(column: NormalizedColumn<any>) {
      const isCurrentlyVisible =
        visibleColumnsHeaders[column.header] ?? !column.defaultHidden;

      setVisibleColumnsHeaders({
        ...visibleColumnsHeaders,
        [column.header]: !isCurrentlyVisible,
      });
    },
  };
}

/**
 * Return a list of sort keys or accessors from sortable columns. Useful to
 * derive a list of allowable columns for validation.
 */
export function getSortableFields<TResource extends object>(
  columns: ReadonlyArray<Column<TResource, any>>,
): Array<string & keyof TResource> {
  // Equivalent to filtering and mapping
  return columns.flatMap((column) => {
    const sortKey = getColumnSortKey(column);

    return sortKey !== undefined ? [sortKey] : [];
  });
}

export function getNormalizedAvailableColumns<
  TResource extends object,
  TForeignResource extends string,
>(
  columns: ReadonlyArray<Column<TResource, TForeignResource>>,
  renderForeignResource: ForeignResourceRenderer<TForeignResource> | undefined,
  apiVersion: ApiVersion,
  deprecationPolicy: DeprecationPolicy,
  versionHistories: VersionHistories<any> | undefined,
): ReadonlyArray<NormalizedColumn<TResource>> {
  return columns.flatMap((column) => {
    const history: VersionHistory | undefined = isAccessorColumn(column)
      ? versionHistories?.[column.accessor]
      : column.versionHistory;

    if (history == null) {
      return normalizeColumn(column, renderForeignResource);
    } else {
      const status = apiVersion.checkStatus(history);

      if (status === "unavailable") {
        return [];
      } else if (deprecationPolicy === "hide" && status === "deprecated") {
        return [];
      } else {
        return normalizeColumn(
          column,
          renderForeignResource,
          status === "deprecated",
        );
      }
    }
  });
}

export function normalizeColumn<
  TResource extends object,
  TForeignResource extends string,
>(
  column: Column<TResource, TForeignResource>,
  renderForeignResource?: ForeignResourceRenderer<TForeignResource>,
  deprecated?: boolean,
): NormalizedColumn<TResource> {
  const { defaultHidden = false } = column;

  const sortKey = getColumnSortKey(column);

  if (isAccessorColumn(column)) {
    const align = getColumnAlignment(column);

    return {
      align,
      defaultHidden,
      header: column.header ?? getFieldLabel(column.accessor),
      sortKey,
      renderCell: makeCellRenderer(column, renderForeignResource),
      deprecated,
    };
  } else {
    return {
      align: column.align,
      defaultHidden,
      header: column.header,
      sortKey,
      renderCell: column.renderCell,
    };
  }
}

function getColumnAlignment(
  column: AccessorColumn<any, any>,
): NormalizedColumn<any>["align"] {
  if (column.align !== undefined) {
    return column.align;
  } else if (column.dataType === "number" || column.dataType === "bytes") {
    return "right";
  } else if (column.dataType === "boolean") {
    return "center";
  } else {
    return undefined;
  }
}

/**
 * Return the sorting key for the given column if one is available.
 *
 * If the column has a `sortKey` field, it is returned. If the column has
 * an `accessor` field, it is returned only if `isSortable === true`. Otherwise,
 * the column is not considered to have a sorting key.
 */
function getColumnSortKey<TResource extends object>(
  column: Column<TResource, any>,
): (string & keyof TResource) | undefined {
  if (isAccessorColumn(column) && column.isSortable) {
    return column.accessor;
  } else if ("sortKey" in column && column.sortKey !== undefined) {
    return column.sortKey;
  } else {
    return undefined;
  }
}

function isAccessorColumn<
  TResource extends object,
  TForeignResource extends string,
>(
  column: Column<TResource, TForeignResource>,
): column is AccessorColumn<TResource, TForeignResource> {
  return "accessor" in column;
}
