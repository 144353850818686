/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ObjectStoreUpdateRequest {
  name?: string;
  bucketName?: string;
  accessKeyId?: string | null;
  secretAccessKey?: string | null;
  regionName?: string | null;
  endpointUrl?: string | null;
  note?: string | null;
  context?: object | null;
  disabled?: boolean;
  _default?: boolean;
  readOnly?: boolean;
  managed?: boolean;
  keyPrefix?: string | null;
}

export function ObjectStoreUpdateRequestFromJSON(
  json: any,
): ObjectStoreUpdateRequest {
  return ObjectStoreUpdateRequestFromJSONTyped(json);
}

export function ObjectStoreUpdateRequestFromJSONTyped(
  json: any,
): ObjectStoreUpdateRequest {
  if (json == null) {
    return json;
  }

  return {
    name: json["name"],
    bucketName: json["bucket_name"],
    accessKeyId: json["access_key_id"],
    secretAccessKey: json["secret_access_key"],
    regionName: json["region_name"],
    endpointUrl: json["endpoint_url"],
    note: json["note"],
    context: json["context"],
    disabled: json["disabled"],
    _default: json["default"],
    readOnly: json["read_only"],
    managed: json["managed"],
    keyPrefix: json["key_prefix"],
  };
}

export function ObjectStoreUpdateRequestToJSON(
  value: ObjectStoreUpdateRequest | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    name: value.name,
    bucket_name: value.bucketName,
    access_key_id: value.accessKeyId,
    secret_access_key: value.secretAccessKey,
    region_name: value.regionName,
    endpoint_url: value.endpointUrl,
    note: value.note,
    context: value.context,
    disabled: value.disabled,
    default: value._default,
    read_only: value.readOnly,
    managed: value.managed,
    key_prefix: value.keyPrefix,
  };
}
