import type React from "react";
import type { Draft } from "immer";
import type { ImmerReducer } from "use-immer";
import { useImmerReducer } from "use-immer";
import { invariant } from "~/lib/invariant";
import { isEqual, maxBy, minBy } from "~/lib/std";
import type { Topic } from "~/lqs";
import type { LoadedPlaybackSource, Panel, PlaybackSource } from "~/player";
import { checkIsPanelInitialized } from "~/player";
import type { TimeRange } from "~/types";
import { assertNever } from "~/utils";
import type { DraftDigestionTopic } from "../../types";

type DigestionSidebarView = "edit" | "list";

interface SetSelectedTopicsAction {
  type: "set-selected-topics";
  payload: {
    topics: ReadonlyArray<Topic>;
  };
}

export function setSelectedTopics(
  payload: SetSelectedTopicsAction["payload"],
): SetSelectedTopicsAction {
  return {
    type: "set-selected-topics",
    payload,
  };
}

interface SelectLayoutTopicsAction {
  type: "select-layout-topics";
}

export function selectLayoutTopics(): SelectLayoutTopicsAction {
  return {
    type: "select-layout-topics",
  };
}

interface DraftSelectedTopicsAction {
  type: "draft-selected-topics";
}

export function draftSelectedTopics(): DraftSelectedTopicsAction {
  return {
    type: "draft-selected-topics",
  };
}

interface DraftTopicAction {
  type: "draft-topic";
  payload: {
    topic: Topic;
    range: TimeRange;
  };
}

export function draftTopic(
  payload: DraftTopicAction["payload"],
): DraftTopicAction {
  return {
    type: "draft-topic",
    payload,
  };
}

interface RemoveDraftTopicAction {
  type: "remove-draft-topic";
  payload: {
    topic: DraftDigestionTopic;
  };
}

export function removeDraftTopic(
  payload: RemoveDraftTopicAction["payload"],
): RemoveDraftTopicAction {
  return {
    type: "remove-draft-topic",
    payload,
  };
}

interface ReturnToEditingAction {
  type: "return-to-editing";
}

export function returnToEditing(): ReturnToEditingAction {
  return {
    type: "return-to-editing",
  };
}

interface ResetDraftAction {
  type: "reset-draft";
}

export function resetDraft(): ResetDraftAction {
  return {
    type: "reset-draft",
  };
}

interface ListDigestionsAction {
  type: "list-digestions";
}

export function listDigestions(): ListDigestionsAction {
  return {
    type: "list-digestions",
  };
}

interface ShowNewDigestionAction {
  type: "show-new-digestion";
}

export function showNewDigestion(): ShowNewDigestionAction {
  return {
    type: "show-new-digestion",
  };
}

export type DraftDigestionActions =
  | SetSelectedTopicsAction
  | SelectLayoutTopicsAction
  | DraftSelectedTopicsAction
  | DraftTopicAction
  | RemoveDraftTopicAction
  | ReturnToEditingAction
  | ResetDraftAction
  | ListDigestionsAction
  | ShowNewDigestionAction;

interface DraftDigestionReducerState {
  view: DigestionSidebarView;
  topics: Array<DraftDigestionTopic>;
  selectedTopicIds: Array<Topic["id"]>;
}

export interface DraftDigestion extends DraftDigestionReducerState {
  canSelectLayoutTopics: boolean;
  dispatch: React.Dispatch<DraftDigestionActions>;
}

const initialState: Readonly<DraftDigestionReducerState> = {
  view: "edit",
  topics: [],
  selectedTopicIds: [],
};

export interface UseDraftDigestionArgs {
  playerTopics: Array<Topic> | undefined;
  playerRange: PlaybackSource["range"];
  panels: ReadonlyArray<Panel>;
}

export function useDraftDigestion({
  playerTopics,
  playerRange,
  panels,
}: UseDraftDigestionArgs): DraftDigestion {
  const layoutTopicNames = new Array<Topic["name"]>();
  for (const panel of panels) {
    if (!checkIsPanelInitialized(panel)) {
      continue;
    }

    for (const topic of panel.topics) {
      if (topic == null) {
        continue;
      }

      layoutTopicNames.push(topic.name);
    }
  }

  const [draftDigestionState, dispatch] = useImmerReducer(
    createReducer({
      playerTopics,
      playerRange,
      layoutTopicNames,
    }),
    initialState,
  );

  return {
    ...draftDigestionState,
    canSelectLayoutTopics: layoutTopicNames.length > 0,
    dispatch,
  };
}

function createReducer({
  playerTopics,
  playerRange,
  layoutTopicNames,
}: Pick<UseDraftDigestionArgs, "playerTopics" | "playerRange"> & {
  layoutTopicNames: ReadonlyArray<Topic["name"]>;
}): ImmerReducer<DraftDigestionReducerState, DraftDigestionActions> {
  return function reducer(draftState, action) {
    invariant(
      playerTopics !== undefined && playerRange !== undefined,
      "Topics and/or range not defined",
    );

    switch (action.type) {
      case "set-selected-topics": {
        draftState.selectedTopicIds = action.payload.topics.map(({ id }) => id);

        return;
      }
      case "select-layout-topics": {
        invariant(layoutTopicNames.length > 0, "No layout topics to select");

        const layoutTopics = playerTopics.filter(({ name }) =>
          layoutTopicNames.includes(name),
        );

        draftState.selectedTopicIds = layoutTopics.map(({ id }) => id);

        return;
      }
      case "draft-selected-topics": {
        invariant(
          draftState.selectedTopicIds.length > 0,
          "Must have at least one topic selected",
        );

        for (const topicId of draftState.selectedTopicIds) {
          addTopicToDraft(draftState, topicId, playerRange);
        }

        return;
      }
      case "draft-topic": {
        addTopicToDraft(
          draftState,
          action.payload.topic.id,
          action.payload.range,
        );

        return;
      }
      case "remove-draft-topic": {
        const topicIndex = draftState.topics.findIndex((topic) =>
          isEqual(topic, action.payload.topic),
        );

        invariant(topicIndex !== -1, "Topic not found");

        draftState.topics.splice(topicIndex, 1);

        return;
      }
      case "return-to-editing": {
        invariant(draftState.view !== "edit", "Already editing");

        draftState.view = "edit";

        return;
      }
      case "reset-draft": {
        return initialState;
      }
      case "list-digestions": {
        invariant(draftState.view !== "list", "Already listing digestions");

        draftState.view = "list";

        return;
      }
      case "show-new-digestion": {
        return {
          ...initialState,
          view: "list",
        };
      }
      default: {
        assertNever(action);
      }
    }
  };
}

function addTopicToDraft(
  draftState: Draft<DraftDigestionReducerState>,
  topicId: Topic["id"],
  playerBounds: LoadedPlaybackSource["bounds"],
): void {
  const newDraftTopic: DraftDigestionTopic = {
    topicId,
    ...playerBounds,
  };

  // If the new topic's time range overlaps with any existing topics who share
  // the same topic ID, those need to be merged into a single topic.
  const overlappedTopics = draftState.topics.filter((topic) => {
    if (topic.topicId !== topicId) {
      return false;
    }

    return areTimeRangesOverlapping(topic, newDraftTopic);
  });

  if (overlappedTopics.length === 0) {
    // New topic didn't overlap with anything so just add it and return.
    draftState.topics.push(newDraftTopic);

    return;
  }

  // New topic and all existing topics it overlapped with need to be merged
  // into a single topic whose time range spans them all.
  const { startTime: minOverlapTime } = minBy(
    [...overlappedTopics, newDraftTopic],
    "startTime",
  )!;
  const { endTime: maxOverlapTime } = maxBy(
    [...overlappedTopics, newDraftTopic],
    "endTime",
  )!;

  // Only keep topics which weren't overlapped by the new topic. Overlapped
  // topics will be represented by the single merged topic.
  draftState.topics = draftState.topics.filter(
    (topic) =>
      !overlappedTopics.some((overlappedTopic) =>
        isEqual(topic, overlappedTopic),
      ),
  );

  // Push the merged topic in place of the new and overlapped topics.
  draftState.topics.push({
    topicId,
    startTime: minOverlapTime,
    endTime: maxOverlapTime,
  });
}

function areTimeRangesOverlapping(x: TimeRange, y: TimeRange): boolean {
  return y.startTime <= x.endTime && x.startTime <= y.endTime;
}
