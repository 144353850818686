import { NewLqsResourceForm, useCreateLog } from "~/lqs";
import { useLqsNavigator } from "~/paths";
import { createLogSchema } from "../schemas";

export function LogCreateForm() {
  const lqsNavigator = useLqsNavigator({ toLogDetails: true });

  return (
    <NewLqsResourceForm
      resourceName="log"
      registryKey="log"
      schema={createLogSchema}
      descriptors={[
        { name: "name", type: "text", required: true },
        {
          name: "groupId",
          type: "foreign-key",
          resourceType: "group",
          required: true,
        },
        {
          name: "defaultWorkflowId",
          type: "foreign-key",
          resourceType: "workflow",
        },
        { name: "baseTimestamp", type: "timestamp" },
        { name: "locked", type: "boolean" },
        { name: "note", type: "text", multiline: true },
        { name: "context", type: "json" },
      ]}
      mutation={useCreateLog()}
      createDetailsLocation={(response) =>
        lqsNavigator.toLogDetails({ logId: response.data.id })
      }
    />
  );
}
