import * as z from "zod";
import {
  MAX_IMAGE_BRIGHTNESS_PCT,
  MAX_IMAGE_CONTRAST_PCT,
  SplitOrientation,
  VisualizationType,
} from "../constants";
import {
  chartFieldsSchema,
  imageFlipDirectionSchema,
  imageRotationSchema,
  layoutProfileVersion,
} from "./common";
import { layoutProfileDescriptorSchema_0_0_0 } from "./models.0-0-0";
import type * as sdkTypes from "./types";

const panelNodeDescriptorSchema: z.ZodType<sdkTypes.PanelNodeDescriptor> =
  z.object({
    id: z.number(),
    size: z.number(),
    panelId: z.number(),
  });

const containerNodeDescriptorSchema: z.ZodType<sdkTypes.ContainerNodeDescriptor> =
  z.object({
    id: z.number(),
    size: z.number(),
    orientation: z.nativeEnum(SplitOrientation),
    children: z.tuple([
      z.lazy(() => layoutNodeDescriptorSchema),
      z.lazy(() => layoutNodeDescriptorSchema),
    ]),
  });

const layoutNodeDescriptorSchema: z.ZodType<sdkTypes.LayoutNodeDescriptor> =
  z.union([panelNodeDescriptorSchema, containerNodeDescriptorSchema]);

const baseTopicDescriptorSchema = z.object({
  name: z.string(),
});

const basePanelDescriptorSchema = z.object({
  id: z.number(),
  title: z.string().nullable(),
});

const uninitializedPanelDescriptorSchema: z.ZodType<sdkTypes.UninitializedPanelDescriptor> =
  basePanelDescriptorSchema.extend({
    visualization: z.literal(null),
    topics: z.tuple([]),
  });

const timelineTopicDescriptorSchema: z.ZodType<sdkTypes.TimelineTopicDescriptor> =
  baseTopicDescriptorSchema;

const timelinePanelDescriptorSchema: z.ZodType<sdkTypes.TimelinePanelDescriptor> =
  basePanelDescriptorSchema.extend({
    visualization: z.literal(VisualizationType.Timeline),
    topics: z.tuple([timelineTopicDescriptorSchema]),
  });

const chartTopicDescriptorSchema: z.ZodType<sdkTypes.ChartTopicDescriptor> =
  baseTopicDescriptorSchema.extend({
    fields: chartFieldsSchema,
  });

const chartPanelDescriptorSchema: z.ZodType<sdkTypes.ChartPanelDescriptor> =
  basePanelDescriptorSchema.extend({
    visualization: z.literal(VisualizationType.Chart),
    topics: z.tuple([chartTopicDescriptorSchema]),
  });

const imageTopicDescriptorSchema: z.ZodType<sdkTypes.ImageTopicDescriptor> =
  baseTopicDescriptorSchema.extend({
    colorize: z.boolean(),
    brightnessPct: z.number().min(0).max(MAX_IMAGE_BRIGHTNESS_PCT),
    contrastPct: z.number().min(0).max(MAX_IMAGE_CONTRAST_PCT),
    rotationDeg: imageRotationSchema,
    flip: imageFlipDirectionSchema.nullable(),
  });

const imagePanelDescriptorSchema: z.ZodType<sdkTypes.ImagePanelDescriptor> =
  basePanelDescriptorSchema.extend({
    visualization: z.literal(VisualizationType.Image),
    topics: z.tuple([imageTopicDescriptorSchema]),
  });

const mapTopicDescriptorSchema: z.ZodType<sdkTypes.MapTopicDescriptor> =
  baseTopicDescriptorSchema;

const mapPanelDescriptorSchema: z.ZodType<sdkTypes.MapPanelDescriptor> =
  basePanelDescriptorSchema.extend({
    visualization: z.literal(VisualizationType.Map),
    topics: z.union([
      z.tuple([mapTopicDescriptorSchema]),
      z.tuple([mapTopicDescriptorSchema, mapTopicDescriptorSchema]),
    ]),
  });

const pointCloudTopicDescriptorSchema: z.ZodType<sdkTypes.PointCloudTopicDescriptor> =
  baseTopicDescriptorSchema.extend({
    size: z.number().min(0).max(0.1),
  });

const threeDPanelDescriptorSchema: z.ZodType<sdkTypes.ThreeDPanelDescriptor> =
  basePanelDescriptorSchema.extend({
    visualization: z.literal(VisualizationType.ThreeD),
    topics: z.tuple([pointCloudTopicDescriptorSchema]),
  });

const panelDescriptorSchema: z.ZodType<sdkTypes.PanelDescriptor> = z.union([
  uninitializedPanelDescriptorSchema,
  timelinePanelDescriptorSchema,
  chartPanelDescriptorSchema,
  imagePanelDescriptorSchema,
  mapPanelDescriptorSchema,
  threeDPanelDescriptorSchema,
]);

const currentLayoutProfileDescriptorSchema = z.object({
  name: z.string(),
  version: z
    .literal(layoutProfileVersion.toString())
    .transform(() => layoutProfileVersion),
  layout: layoutNodeDescriptorSchema,
  panels: z.array(panelDescriptorSchema),
}) satisfies z.ZodType<sdkTypes.LayoutProfileDescriptor, z.ZodTypeDef, unknown>;

const layoutProfileDescriptorSchema = z.union([
  currentLayoutProfileDescriptorSchema,
  layoutProfileDescriptorSchema_0_0_0.transform(
    (value): sdkTypes.LayoutProfileDescriptor => {
      const result_v_0_1_0: z.input<
        typeof currentLayoutProfileDescriptorSchema
      > = {
        ...value,
        version: layoutProfileVersion.toString(),
        panels: value.panels.map((panel) => ({
          ...panel,
          title: panel.visualization === null ? null : panel.topics[0].name,
        })),
      };

      return currentLayoutProfileDescriptorSchema.parse(result_v_0_1_0);
    },
  ),
]);

export function deserializeLayoutProfileDescriptors(
  input: unknown,
): ReadonlyArray<sdkTypes.LayoutProfileDescriptor> {
  return z.array(layoutProfileDescriptorSchema).parse(input);
}

export function serializeLayoutProfileDescriptors(
  profiles:
    | sdkTypes.LayoutProfileDescriptor
    | ReadonlyArray<sdkTypes.LayoutProfileDescriptor>,
): ReadonlyArray<object> {
  if (!Array.isArray(profiles)) {
    profiles = [profiles as sdkTypes.LayoutProfileDescriptor];
  }

  return profiles;
}
