import type React from "react";
import type { StrictOmit } from "ts-essentials";
import { createSafeContext } from "~/contexts";
import type {
  ApiVersion,
  ResourceVersionHistories,
  VersionGuardProps,
  VersionHistory,
} from "~/domain/versioning";
import { v, VersionGuard } from "~/domain/versioning";
import { processResourceVersionHistories } from "./process";
import type {
  Digestion,
  DigestionCreateRequest,
  DigestionPart,
  DigestionPartCreateRequest,
  DigestionPartUpdateRequest,
  DigestionUpdateRequest,
  Group,
  GroupCreateRequest,
  GroupUpdateRequest,
  Ingestion,
  IngestionCreateRequest,
  IngestionPart,
  IngestionPartCreateRequest,
  IngestionPartUpdateRequest,
  IngestionUpdateRequest,
  Label,
  LabelCreateRequest,
  LabelUpdateRequest,
  ListDigestionPartsRequest,
  ListDigestionsRequest,
  ListGroupsRequest,
  ListIngestionPartsRequest,
  ListIngestionsRequest,
  ListLabelsRequest,
  ListLogsRequest,
  ListObjectStoresRequest,
  ListTopicsRequest,
  ListWorkflowsRequest,
  Log,
  LogCreateRequest,
  LogUpdateRequest,
  ObjectStore,
  ObjectStoreCreateRequest,
  ObjectStoreUpdateRequest,
  Topic,
  TopicCreateRequest,
  TopicUpdateRequest,
  Workflow,
  WorkflowCreateRequest,
  WorkflowUpdateRequest,
} from "./sdk";
import type { LqsResource, LqsResourceRegistry } from "./types";

const [useLqsVersion, LqsVersionContext] =
  createSafeContext<ApiVersion>("LqsVersion");

export { useLqsVersion };

export function LqsVersionProvider({
  version,
  children,
}: {
  version: ApiVersion;
  children: React.ReactNode;
}) {
  return (
    <LqsVersionContext.Provider value={version}>
      {children}
    </LqsVersionContext.Provider>
  );
}

export function LqsVersionGuard(
  props: StrictOmit<VersionGuardProps, "apiVersion">,
) {
  const lqsVersion = useLqsVersion();

  return <VersionGuard {...props} apiVersion={lqsVersion} />;
}

export function LqsVersionSwitch({
  children,
}: {
  children: ReadonlyArray<
    [
      versionHistory: VersionHistory,
      content: React.ReactNode | (() => React.ReactNode),
    ]
  >;
}) {
  return children.map(([versionHistory, content]) => (
    <LqsVersionGuard
      key={createVersionHistoryKey(versionHistory)}
      versionHistory={versionHistory}
      current={content}
    />
  ));
}

function createVersionHistoryKey(versionHistory: VersionHistory): string {
  const segments = new Array<string>();
  for (const [key, version] of Object.entries(versionHistory)) {
    segments.push(`${key}:${version}`);
  }

  return segments.join(";");
}

const V_1_1_0 = v(1, 1, 0);

export const digestionVersionHistories = {
  model: {
    ...processResourceVersionHistories.model,
  },
  operations: {
    list: {
      ...processResourceVersionHistories.operations.list,
    },
    update: {
      ...processResourceVersionHistories.operations.update,
    },
  },
} satisfies ResourceVersionHistories<
  Digestion,
  ListDigestionsRequest,
  DigestionCreateRequest,
  DigestionUpdateRequest
>;

export const digestionPartVersionHistories = {
  model: {
    ...processResourceVersionHistories.model,
    context: {
      added: V_1_1_0,
    },
  },
  operations: {
    create: {
      context: {
        added: V_1_1_0,
      },
    },
    update: {
      ...processResourceVersionHistories.operations.update,
      context: {
        added: V_1_1_0,
      },
    },
  },
} satisfies ResourceVersionHistories<
  DigestionPart,
  ListDigestionPartsRequest,
  DigestionPartCreateRequest,
  DigestionPartUpdateRequest
>;

export const groupVersionHistories = {
  model: {
    defaultWorkflowId: {
      deprecated: V_1_1_0,
    },
  },
  operations: {
    update: {
      defaultWorkflowId: {
        deprecated: V_1_1_0,
      },
    },
  },
} satisfies ResourceVersionHistories<
  Group,
  ListGroupsRequest,
  GroupCreateRequest,
  GroupUpdateRequest
>;

export const ingestionVersionHistories = {
  model: {
    ...processResourceVersionHistories.model,
  },
  operations: {
    list: {
      ...processResourceVersionHistories.operations.list,
    },
    update: {
      ...processResourceVersionHistories.operations.update,
    },
  },
} satisfies ResourceVersionHistories<
  Ingestion,
  ListIngestionsRequest,
  IngestionCreateRequest,
  IngestionUpdateRequest
>;

export const ingestionPartVersionHistories = {
  model: {
    ...processResourceVersionHistories.model,
    context: {
      added: V_1_1_0,
    },
  },
  operations: {
    create: {
      context: {
        added: V_1_1_0,
      },
    },
    update: {
      ...processResourceVersionHistories.operations.update,
      context: {
        added: V_1_1_0,
      },
    },
  },
} satisfies ResourceVersionHistories<
  IngestionPart,
  ListIngestionPartsRequest,
  IngestionPartCreateRequest,
  IngestionPartUpdateRequest
>;

export const labelVersionHistories = {
  model: {
    category: {
      added: V_1_1_0,
    },
  },
  operations: {
    create: {
      category: {
        added: V_1_1_0,
      },
    },
    update: {
      category: {
        added: V_1_1_0,
      },
    },
  },
} satisfies ResourceVersionHistories<
  Label,
  ListLabelsRequest,
  LabelCreateRequest,
  LabelUpdateRequest
>;

export const logVersionHistories = {
  model: {
    defaultWorkflowId: {
      deprecated: V_1_1_0,
    },
    duration: {
      added: V_1_1_0,
    },
    objectCount: {
      deprecated: V_1_1_0,
    },
    objectSize: {
      deprecated: V_1_1_0,
    },
    baseTimestamp: {
      added: V_1_1_0,
    },
  },
  operations: {
    create: {
      defaultWorkflowId: {
        deprecated: V_1_1_0,
      },
      baseTimestamp: {
        added: V_1_1_0,
      },
    },
    update: {
      defaultWorkflowId: {
        deprecated: V_1_1_0,
      },
      baseTimestamp: {
        added: V_1_1_0,
      },
    },
  },
} satisfies ResourceVersionHistories<
  Log,
  ListLogsRequest,
  LogCreateRequest,
  LogUpdateRequest
>;

export const objectStoreVersionHistories = {
  model: {
    _default: {
      added: V_1_1_0,
    },
    keyPrefix: {
      added: V_1_1_0,
    },
    managed: {
      added: V_1_1_0,
    },
    name: {
      added: V_1_1_0,
    },
    readOnly: {
      added: V_1_1_0,
    },
  },
  operations: {
    list: {
      name: {
        added: V_1_1_0,
      },
      nameLike: {
        added: V_1_1_0,
      },
    },
    create: {
      _default: {
        added: V_1_1_0,
      },
      keyPrefix: {
        added: V_1_1_0,
      },
      managed: {
        added: V_1_1_0,
      },
      name: {
        added: V_1_1_0,
      },
      readOnly: {
        added: V_1_1_0,
      },
    },
    update: {
      _default: {
        added: V_1_1_0,
      },
      keyPrefix: {
        added: V_1_1_0,
      },
      managed: {
        added: V_1_1_0,
      },
      name: {
        added: V_1_1_0,
      },
      readOnly: {
        added: V_1_1_0,
      },
    },
  },
} satisfies ResourceVersionHistories<
  ObjectStore,
  ListObjectStoresRequest,
  ObjectStoreCreateRequest,
  ObjectStoreUpdateRequest
>;

export const topicVersionHistories = {
  model: {
    duration: {
      added: V_1_1_0,
    },
    objectCount: {
      deprecated: V_1_1_0,
    },
    objectSize: {
      deprecated: V_1_1_0,
    },
  },
} satisfies ResourceVersionHistories<
  Topic,
  ListTopicsRequest,
  TopicCreateRequest,
  TopicUpdateRequest
>;

export const workflowVersionHistories = {
  model: {
    processType: {
      added: V_1_1_0,
    },
  },
  operations: {
    list: {
      processType: {
        added: V_1_1_0,
      },
    },
    create: {
      processType: {
        added: V_1_1_0,
      },
    },
  },
} satisfies ResourceVersionHistories<
  Workflow,
  ListWorkflowsRequest,
  WorkflowCreateRequest,
  WorkflowUpdateRequest
>;

export type LqsResourceVersionRegistry = {
  [Resource in LqsResource]?: ResourceVersionHistories<
    LqsResourceRegistry[Resource]["model"],
    LqsResourceRegistry[Resource]["listRequest"],
    LqsResourceRegistry[Resource]["createRequest"],
    LqsResourceRegistry[Resource]["updateRequest"]
  >;
};

export const lqsResourceVersionRegistry: LqsResourceVersionRegistry = {
  digestion: digestionVersionHistories,
  digestionPart: digestionPartVersionHistories,
  group: groupVersionHistories,
  ingestion: ingestionVersionHistories,
  ingestionPart: ingestionPartVersionHistories,
  label: labelVersionHistories,
  log: logVersionHistories,
  objectStore: objectStoreVersionHistories,
  topic: topicVersionHistories,
  workflow: workflowVersionHistories,
};
