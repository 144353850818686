import { invariant } from "~/lib/invariant";
import type { Topic } from "~/lqs";
import { useLoadedPlayerTopics } from "../hooks";
import { usePanelContext } from "./nodes";
import type { InitializedPanel } from "./types";
import {
  checkIsPanelInitialized,
  findTopicForDescriptor,
  getPrimaryTopicDescriptor,
} from "./utils";

export function useInitializedPanel(): InitializedPanel {
  const panel = usePanelContext();

  invariant(checkIsPanelInitialized(panel), "Panel is not initialized");

  return panel;
}

export function usePanelTopic(): Topic {
  const panel = useInitializedPanel();

  const playerTopics = useLoadedPlayerTopics();

  const topicDescriptor = getPrimaryTopicDescriptor(panel);

  const topic = findTopicForDescriptor(topicDescriptor, playerTopics);

  invariant(topic != null, "No topic found for panel");

  return topic;
}
