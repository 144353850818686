import React from "react";
import { FormControlLabel, Slider, Switch, Typography } from "@mui/material";
import { usePlayerActions } from "../../actions";
import type { ImagePanelWithInference } from "../../panels";
import { getInferenceTopicDescriptor } from "../../panels";

export function ImageResultsControls({
  panel,
}: {
  panel: ImagePanelWithInference;
}) {
  const topicDescriptor = getInferenceTopicDescriptor(panel);

  const playerActions = usePlayerActions();

  function handleOpacityChange(_: unknown, newOpacity: number | number[]) {
    playerActions.setInferenceImageOpacity(panel, newOpacity as number);
  }

  function handleInferenceImageColorizationToggle(
    e: React.ChangeEvent<HTMLInputElement>,
  ) {
    playerActions.toggleInferenceImageColorization(panel, e.target.checked);
  }

  return (
    <>
      <Typography>Opacity</Typography>
      <Slider
        sx={{
          alignSelf: "center",
          width: (theme) => `calc(100% - ${theme.spacing(2.5)})`,
        }}
        min={0}
        max={1}
        step={0.01}
        value={topicDescriptor.opacity}
        onChange={handleOpacityChange}
      />
      <FormControlLabel
        sx={{ justifyContent: "space-between", ml: 0 }}
        control={
          <Switch
            checked={topicDescriptor.colorize}
            onChange={handleInferenceImageColorizationToggle}
          />
        }
        label="Colorize"
        labelPlacement="start"
      />
    </>
  );
}
