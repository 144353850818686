import React, { useState } from "react";
import type { QueryClient } from "@tanstack/react-query";
import { QueryClientProvider } from "@tanstack/react-query";
import { createSafeContext } from "~/contexts";
import { createQueryClient } from "~/create-query-client";
import { useAuthenticationStatusQuery } from "./api";
import type { AuthenticationStatus } from "./types";

export const AuthQueryClientContext = React.createContext<
  QueryClient | undefined
>(undefined);

const [useAuthenticationStatus, AuthenticationStatusContext] =
  createSafeContext<AuthenticationStatus>("AuthenticationStatus");

export { useAuthenticationStatus };

export function AuthenticationStatusProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [queryClient] = useState(createQueryClient);

  return (
    <QueryClientProvider client={queryClient} context={AuthQueryClientContext}>
      <InnerProvider>{children}</InnerProvider>
    </QueryClientProvider>
  );
}

// `useAuthenticatedStatusQuery` needs to be rendered below the `QueryClientProvider`
// so another component is needed here to encapsulate the query.
function InnerProvider({ children }: { children: React.ReactNode }) {
  const query = useAuthenticationStatusQuery();

  const authenticationStatus = query.data ?? "pending";

  return (
    <AuthenticationStatusContext.Provider value={authenticationStatus}>
      {children}
    </AuthenticationStatusContext.Provider>
  );
}

// For testing
export function StaticAuthenticationStatusProvider({
  status,
  children,
}: {
  status: AuthenticationStatus;
  children: React.ReactNode;
}) {
  return (
    <AuthenticationStatusContext.Provider value={status}>
      {children}
    </AuthenticationStatusContext.Provider>
  );
}
