import { NewLqsResourceForm, useCreateObjectStore } from "~/lqs";
import { useLqsNavigator } from "~/paths";
import { createObjectStoreSchema } from "../schemas";

export function ObjectStoreCreateForm() {
  const lqsNavigator = useLqsNavigator({ toObjectStoreDetails: true });

  return (
    <NewLqsResourceForm
      resourceName="object store"
      registryKey="objectStore"
      schema={createObjectStoreSchema}
      descriptors={[
        { name: "bucketName", type: "text", required: true },
        { name: "name", type: "text", required: true },
        { name: "keyPrefix", type: "text" },
        { name: "accessKeyId", type: "text" },
        { name: "secretAccessKey", type: "text" },
        { name: "regionName", type: "text" },
        { name: "endpointUrl", type: "text" },
        { name: "_default", type: "boolean" },
        { name: "readOnly", type: "boolean" },
        { name: "managed", type: "boolean" },
        { name: "disabled", type: "boolean" },
        { name: "note", type: "text", multiline: true },
        { name: "context", type: "json" },
      ]}
      mutation={useCreateObjectStore()}
      createDetailsLocation={(response) =>
        lqsNavigator.toObjectStoreDetails({ objectStoreId: response.data.id })
      }
    />
  );
}
