import type { ElementOf } from "ts-essentials";
import { get, modulo } from "~/lib/std";
import type { Topic } from "~/lqs";
import { treeIterable } from "~/utils";
import { VisualizationType } from "./constants";
import type {
  ImagePanelWithInference,
  InferenceTopicDescriptor,
  InitializedPanel,
  LayoutNodeDescriptor,
  Panel,
} from "./types";

export function layoutTreeIterable(root: LayoutNodeDescriptor) {
  return treeIterable(root, (node: LayoutNodeDescriptor) => {
    if ("children" in node) {
      return node.children;
    }
  });
}

export function calculateRotationQuadrant(rotationDeg: number): number {
  // Integer in range [0, 3] representing the effective number of 90 degree
  // clockwise rotations
  return modulo(rotationDeg, 360) / 90;
}

export function checkIsPanelInitialized<TPanel extends Panel>(
  panel: TPanel,
): panel is InitializedPanel & TPanel {
  return panel.visualization !== null;
}

export function getPrimaryTopicDescriptor<TPanel extends InitializedPanel>(
  panel: TPanel,
): TPanel["topics"][0] {
  return panel.topics[0];
}

export function findTopicForDescriptor(
  descriptor: NonNullable<ElementOf<Panel["topics"]>>,
  topics: ReadonlyArray<Topic> | undefined,
): Topic | undefined {
  return topics?.find((topic) => topic.name === descriptor.name);
}

export function checkIsImagePanelWithInference(
  panel: InitializedPanel,
): panel is ImagePanelWithInference {
  return (
    panel.visualization === VisualizationType.Image && panel.topics[1] != null
  );
}

export function getInferenceTopicDescriptor(
  panel: ImagePanelWithInference,
): InferenceTopicDescriptor {
  return panel.topics[1];
}

export function getTopicContextDisplayName(
  context: Topic["context"],
): string | undefined {
  const displayName = get(context, "studio.display_name");

  if (displayName == null) {
    return undefined;
  } else {
    return String(displayName);
  }
}
