/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface LogUpdateRequest {
  locked?: boolean;
  groupId?: string;
  name?: string;
  note?: string | null;
  baseTimestamp?: bigint | null;
  context?: object | null;
  defaultWorkflowId?: string | null;
}

export function LogUpdateRequestFromJSON(json: any): LogUpdateRequest {
  return LogUpdateRequestFromJSONTyped(json);
}

export function LogUpdateRequestFromJSONTyped(json: any): LogUpdateRequest {
  if (json == null) {
    return json;
  }

  return {
    locked: json["locked"],
    groupId: json["group_id"],
    name: json["name"],
    note: json["note"],
    baseTimestamp:
      json["base_timestamp"] === undefined
        ? undefined
        : json["base_timestamp"] == null
          ? json["base_timestamp"]
          : BigInt(json["base_timestamp"]),
    context: json["context"],
    defaultWorkflowId: json["default_workflow_id"],
  };
}

export function LogUpdateRequestToJSON(
  value: LogUpdateRequest | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    locked: value.locked,
    group_id: value.groupId,
    name: value.name,
    note: value.note,
    base_timestamp:
      value.baseTimestamp === undefined
        ? undefined
        : value.baseTimestamp === null
          ? null
          : value.baseTimestamp.toString(),
    context: value.context,
    default_workflow_id: value.defaultWorkflowId,
  };
}
