/**
 * This file exists simply to provide a way to clean up data in `localStorage`
 * from a legacy feature. The feature may return in the future but, for now, we
 * shouldn't be keeping unnecessary data around.
 */

const RECENT_MODELS_STORAGE_KEY = "recent-models";

export function cleanUpRecentModels(): void {
  localStorage.removeItem(RECENT_MODELS_STORAGE_KEY);
}
