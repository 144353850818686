import { invariant } from "~/lib/invariant";
import { layoutProfileVersion } from "./common";
import {
  serializeLayoutProfileDescriptors,
  deserializeLayoutProfileDescriptors,
} from "./models";
import type * as sdkTypes from "./types";

export const STORAGE_KEY = "layout-profiles";

export async function getLayoutProfiles(): Promise<
  ReadonlyArray<sdkTypes.LayoutProfileDescriptor>
> {
  let storedValue: string | null;
  try {
    storedValue = localStorage.getItem(STORAGE_KEY);
  } catch {
    throw new Error("Couldn't load profiles from storage");
  }

  if (storedValue === null) {
    return [];
  }

  let parsedValue: unknown;
  try {
    parsedValue = JSON.parse(storedValue);
  } catch {
    throw new Error("Stored profiles were corrupted");
  }

  try {
    const profiles = deserializeLayoutProfileDescriptors(parsedValue);

    writeProfilesToStorage(profiles);

    return profiles;
  } catch {
    throw new Error("Stored profiles were corrupted");
  }
}

export async function createLayoutProfile(
  newProfile: Pick<
    sdkTypes.LayoutProfileDescriptor,
    "name" | "layout" | "panels"
  >,
): Promise<void> {
  const currentProfiles = await getLayoutProfiles();

  invariant(
    !currentProfiles.some((profile) => profile.name === newProfile.name),
    `Profile with name "${newProfile.name}" already exists`,
  );

  writeProfilesToStorage([
    ...currentProfiles,
    { ...newProfile, version: layoutProfileVersion },
  ]);
}

export async function deleteLayoutProfile(
  profileName: sdkTypes.LayoutProfileDescriptor["name"],
): Promise<void> {
  const currentProfiles = await getLayoutProfiles();

  invariant(
    currentProfiles.some((profile) => profile.name === profileName),
    `Couldn't find profile with name "${profileName}" to delete`,
  );

  writeProfilesToStorage(
    currentProfiles.filter((profile) => profile.name !== profileName),
  );
}

function writeProfilesToStorage(
  profiles: ReadonlyArray<sdkTypes.LayoutProfileDescriptor>,
): void {
  try {
    localStorage.setItem(
      STORAGE_KEY,
      JSON.stringify(serializeLayoutProfileDescriptors(profiles)),
    );
  } catch {
    /* noop */
  }
}
