// Adapted from https://stackoverflow.com/a/50590586

/**
 * Performs a breadth-first traversal of an arbitrary tree starting from the
 * root, calling the visitor function for each node as it is reached. To cancel
 * the traversal before every node is visited, the visitor can return
 * false; otherwise, the visitor should not return anything.
 *
 * @param root the root(s) of the tree to traverse
 * @param getChildren a function used to retrieve a node's children, if any.
 *        If the node is a leaf the function can return nothing.
 * @param visitor a function to be called on each node found during the
 *        traversal. Can return false to stop traversal early
 */
export function traverseTree<TTree>(
  root: TTree | ReadonlyArray<TTree>,
  getChildren: (node: TTree) => ReadonlyArray<TTree> | undefined | void,
  visitor: (node: TTree) => false | undefined | void,
): void {
  for (const node of treeIterable(root, getChildren)) {
    if (visitor(node) === false) {
      break;
    }
  }
}

export function treeIterable<TTree>(
  root: TTree | ReadonlyArray<TTree>,
  getChildren: (node: TTree) => ReadonlyArray<TTree> | undefined | void,
): Iterable<TTree> {
  return {
    [Symbol.iterator]() {
      const queue = Array.isArray(root) ? [...root] : [root];

      return {
        next() {
          if (queue.length === 0) {
            return { done: true, value: undefined };
          }

          const node = queue.shift()!;

          const children = getChildren(node);
          if (Array.isArray(children)) {
            queue.push(...children);
          }

          return { done: false, value: node };
        },
      };
    },
  };
}
